import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PaginationResponse, Team } from 'models';

type RerollState = {
  reroll: boolean;
};

const initialState: RerollState = {
  reroll: false,
};

export const rerollSlice = createSlice({
  name: 'reroll',
  initialState,
  reducers: {
    setReroll(state, action: PayloadAction<RerollState>) {
      state.reroll = action.payload.reroll;
    },
  },
  selectors: {
    selectReroll: (state: RerollState) => state.reroll,
  },
});

export default rerollSlice.reducer;

export const { setReroll } = rerollSlice.actions;

export const { selectReroll } = rerollSlice.selectors;
