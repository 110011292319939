import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Flex, Grid, Link, useToast } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import routes from 'routes';

import { useLazyGetUserQuery } from 'api/auth';
import { useCreateFakeUserMutation } from 'api/contest';

import { useAppSelector } from 'hooks/app';

import ContestSlider from './ContestSlider';
import FeedbackChat from './FeedbackChat';
import PromoSticker from './PromoSticker';

const AuthHome = () => {
  const { contest, previousContest, nextContest } = useAppSelector((store) => store.contest);
  const user = useAppSelector((store) => store.auth.user);

  if (!contest) return null;

  return (
    <Grid w="100%" h="100%" gridTemplateRows="auto 1fr" pb="20px" data-cy="auth-home">
      <Box textAlign="center" color="white">
        <Box fontSize="14px" fontWeight="600" opacity="0.6">
          wsg
        </Box>
        <Flex
          as={Link}
          href={routes.profile}
          fontSize="18px"
          fontWeight="800"
          lineHeight="1.2"
          alignItems="center"
          justifyContent="center"
          position="relative"
          w="min-content"
          margin="0 auto"
          whiteSpace="nowrap"
          p="0 10px"
          textDecoration="none !important"
          _active={{
            textDecoration: 'none',
            opacity: '0.5',
            transition: 'opacity 0.2s',
          }}
        >
          {user?.username}
          <Box
            w="16px"
            h="16px"
            pos="relative"
            left="10px"
            opacity="0.6"
            _before={{
              content: '""',
              w: '1.5px',
              h: '6px',
              bg: '#fff',
              pos: 'absolute',
              top: '3.3px',
              left: '0',
              borderRadius: '1px',
              transform: 'rotate(-45deg)',
            }}
            _after={{
              content: '""',
              w: '1.5px',
              h: '6px',
              bg: '#fff',
              pos: 'absolute',
              top: '7px',
              left: '0',
              borderRadius: '1px',
              transform: 'rotate(45deg)',
            }}
          />
        </Flex>
      </Box>

      <ContestSlider previous={previousContest} next={nextContest} current={contest} />
      <PromoSticker />
    </Grid>
  );
};

export default AuthHome;
