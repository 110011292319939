import { useEffect, useRef, useState } from 'react';

import { Box, Flex, HStack, Image, Text, useColorModeValue } from '@chakra-ui/react';

interface CoinCounterProps {
  coins: number;
  onCountingComplete?: () => void;
}

const CoinCounter = ({ coins, onCountingComplete }: CoinCounterProps) => {
  const [count, setCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const counterRef = useRef(null);
  const countingCompleteRef = useRef(false);

  const targetCoins = Math.min(Math.max(0, coins || 0), 399);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isVisible) return;

    let start = 0;
    const duration = 1000;
    const increment = 15;
    let timer: NodeJS.Timeout;

    const updateCounter = () => {
      const progress = Math.min((Date.now() - start) / duration, 1);
      const nextCount = Math.floor(progress * targetCoins);

      setCount(nextCount);

      if (progress < 1) {
        timer = setTimeout(updateCounter, increment);
      } else {
        setCount(targetCoins);

        if (onCountingComplete && !countingCompleteRef.current) {
          countingCompleteRef.current = true;
          onCountingComplete();
        }
      }
    };

    start = Date.now();
    updateCounter();

    return () => clearTimeout(timer);
  }, [isVisible, targetCoins]);

  return (
    <Flex ref={counterRef} transition="transform 0.3s" justifyContent="center" w="100%">
      <HStack spacing={1} justify="center">
        <Image src="coin.png" alt="Coin" boxSize="40px" />
        <Text fontSize="40px" fontWeight="800">
          {count}
        </Text>
      </HStack>
    </Flex>
  );
};

export default CoinCounter;
