import { useState } from 'react';

import { Box, Flex, Img, Text } from '@chakra-ui/react';
import { Card, Contest, ContestParticipant, ContestStatus } from 'models';
import { getOrdinalSuffix } from 'utils/getOriginalSuffix';

import DetailedCard from 'components/builder/DetailedCard';

import SquadFooter from './SquadFooter';

interface SquadDetailedInfoProps {
  participation: ContestParticipant;
  cards: Card[];
  contestStatus?: ContestStatus;
  contest?: Contest;
}

const options = [
  { label: 'squad', value: 'squad' },
  { label: 'cut', value: 'cut' },
];

const SquadDetailedInfo = ({ participation, cards, contestStatus, contest }: SquadDetailedInfoProps) => {
  const place = participation.place ?? 'TBA';
  const displayPlace = contestStatus === 'completed' ? place : 'TBA';
  const [selectedOption, setSelectedOption] = useState('squad');

  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
  };

  return (
    <>
      <Flex mt="20px" gap="24px" alignItems="flex-start" justify="center" zIndex={50} position="relative">
        <Box textAlign="center">
          <Flex align="center" h="29px">
            {contestStatus === 'completed' ? (
              <>
                <Img w="20px" h="20px" src="/coin.png" />
                <Box fontSize="24px" fontWeight="800" lineHeight="24px">
                  {participation.totalPoints ?? 'TBA'}
                </Box>
              </>
            ) : (
              <Box fontSize="24px" fontWeight="800" lineHeight="24px">
                TBA
              </Box>
            )}
          </Flex>
          <Box fontSize="12px" fontWeight="600" lineHeight="14.4px" color="#FFFFFFCC">
            earned
          </Box>
        </Box>
        <Box textAlign="center">
          <Flex
            alignItems="center"
            justifyContent="center"
            fontSize="24px"
            fontWeight="800"
            lineHeight="24px"
            h="29px"
            align="baseline"
          >
            <Flex alignItems="flex-end">
              <Box lineHeight="1">{displayPlace}</Box>
              {displayPlace !== 'TBA' && (
                <Flex alignItems="flex-end" lineHeight="1" fontSize="10px" pb="2px">
                  {getOrdinalSuffix(displayPlace)}
                </Flex>
              )}
            </Flex>
          </Flex>
          <Box fontSize="12px" fontWeight="600" lineHeight="14.4px" color="#FFFFFFCC">
            place
          </Box>
        </Box>
      </Flex>
      {contest?.modifier && (
        <Flex mt="20px" px="12px" width="auto" justifyContent="center">
          <Text
            bg="rgba(255, 255, 255, 0.06)"
            borderRadius="12px"
            fontSize="14px"
            fontWeight="600"
            color="#FFFFFFCC"
            textAlign="center"
            lineHeight="1.3"
            mx="auto"
            maxWidth="310px"
            p="12px 28px"
            zIndex="99"
          >
            {contest?.modifier?.name}: {contest?.modifier?.description}
          </Text>
        </Flex>
      )}
      {contestStatus === 'completed' && !participation.boosterUpdatedText && (
        <>
          <Flex mt="20px" px="12px" borderBottom="1px solid rgba(255,255,255,0.4)" bg="#242424">
            {options.map((option) => (
              <Box
                key={option.value}
                flex="1"
                p="8px 0 16px"
                borderBottom={selectedOption === option.value ? '1px solid white' : '1px solid rgba(255,255,255,0.4)'}
                color={selectedOption === option.value ? 'white' : 'rgba(255,255,255,0.4)'}
                textAlign="center"
                fontSize="16px"
                lineHeight="1"
                fontWeight="800"
                cursor="pointer"
                mb="-1px"
                onClick={() => handleOptionChange(option.value)}
                textDecoration="none !important"
                transition="all 0.2s ease-in-out"
                _active={{ color: 'rgba(255,255,255,0.5)', borderColor: 'rgba(255,255,255,0.5)' }}
              >
                {option.label}
              </Box>
            ))}
          </Flex>
          <Box mt="8px">
            {cards
              .filter((card) => (selectedOption === 'squad' ? card.status !== 'cut' : card.status === 'cut'))
              .map((card, index, initialArray) => (
                <Box
                  key={`card-${index}`}
                  opacity={index === initialArray.length - 1 || card.status === 'cut' ? 0.5 : 1}
                  borderBottom={index === initialArray.length - 1 ? 'none' : '0.5px solid rgba(255,255,255,0.3)'}
                >
                  <DetailedCard card={card} booster={participation.booster} key={card.id} />
                </Box>
              ))}
          </Box>
          {selectedOption !== 'cut' && (
            <Box fontSize="14px" fontWeight="600" color="#FFFFFFCC" textAlign="center" mt="40px">
              Only 3 top players count
            </Box>
          )}
        </>
      )}
      {/* {participation.boosterUpdatedText && (
        <Flex flexDirection="column" gap="8px" alignItems="center" mt="20px">
          <Box as={BoosterUpdated} w="24px" h="24px" />
          <Text
            w="100%"
            maxW="150px"
            lineHeight={1.2}
            textAlign="center"
            fontSize="14px"
            fontWeight="600"
            color="#fff"
            opacity={0.8}
            css={{
              textWrap: 'balance',
            }}
          >
            {participation.boosterUpdatedText || 'Booster was updated, scores are correct'}
          </Text>
        </Flex>
      )} */}
      <SquadFooter />
    </>
  );
};

export default SquadDetailedInfo;
