export const formatNumberWithCommas = (num: number) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export function nFormatter(num: number, digits: number) {
  const symbols = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];

  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;

  let item = symbols[0];
  for (let i = 0; i < symbols.length; i++) {
    if (num >= symbols[i].value) {
      item = symbols[i];
    }
  }

  return item ? (num / item.value).toFixed(digits).replace(regexp, '').concat(item.symbol) : '0';
}
