import { useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Box, Flex, keyframes } from '@chakra-ui/react';
import dayjs from 'dayjs';
import routes from 'routes';
import { formatDuration } from 'utils/formatDuration';

import { useLazyCheckSpinQuery } from 'api/contest';

import { useAppSelector } from 'hooks/app';

import { ReactComponent as BoostersIcon } from 'icons/boosters-icon.svg';
import { ReactComponent as LogoIcon } from 'icons/logo.svg';

const hiddenRoutes = [routes.builder, routes.boosters, routes.allBoosters, routes.leaderboard, routes.signIn];

const Header = () => {
  const isAuthicated = useAppSelector((state) => state.auth.isAuthicated);
  const participation = useAppSelector((state) => state.contest.participation);
  const { pathname } = useLocation();

  const isHidden = useMemo(() => {
    if (
      (pathname.startsWith(routes.home) && isAuthicated) ||
      hiddenRoutes.some((r) => pathname.startsWith(r)) ||
      (pathname.startsWith(routes.squad) && participation?.status === 'completed') ||
      pathname.startsWith(routes.profile)
    ) {
      return true;
    }
    return false;
  }, [participation, pathname, isAuthicated]);

  const isWide = useMemo(() => {
    if (pathname === '/' && isAuthicated) {
      return true;
    }
    if (participation?.status === 'completed' && !pathname.startsWith(routes.profile)) {
      return true;
    }
    if (pathname.startsWith(routes.leaderboard)) {
      return true;
    }
    return false;
  }, [isAuthicated, pathname]);

  return (
    <Flex
      alignItems="center"
      justify="center"
      overflow={isHidden && !isWide ? 'hidden' : 'initial'}
      h={isHidden ? '0px' : '48px'}
      zIndex="100"
      pos="relative"
    >
      <Box
        as={Link}
        to={routes.home}
        __css={
          isWide
            ? {
                pos: 'absolute',
                top: '8px',
                left: '16px',
              }
            : undefined
        }
      >
        <LogoIcon />
      </Box>
      {isWide && isAuthicated && pathname === '/' && <BoosterButton />}
    </Flex>
  );
};

export default Header;

const shineAnimation = keyframes`
  0% {
    box-shadow: 0 0 20px 15px #F83C3C;
  }
  50% {
    box-shadow: 0 0 20px 8px #F83C3C;
  }
  100% {
    box-shadow: 0 0 20px 15px #F83C3C;
  }
`;

const fadeIdAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const BoosterButton = ({}) => {
  const [checkSpinStatus, { data, isLoading, isFetching }] = useLazyCheckSpinQuery();
  const now = dayjs().tz('America/Los_Angeles');
  const tommorow = dayjs().tz('America/Los_Angeles').add(1, 'day').startOf('day');
  const locksDiff = tommorow.diff(now);
  const durationUntilLocks = dayjs.duration(locksDiff);
  const time = formatDuration(durationUntilLocks);
  const buttonText = parseInt(time) < 0 ? 'soon' : `${time}`;

  useEffect(() => {
    if (!data) {
      checkSpinStatus();
    }
  }, []);

  if (!data || isLoading) {
    return null;
  }

  return (
    <Box
      animation={
        `${fadeIdAnimation} .5s ease-in forwards` +
        `${data?.canSpinToday ? ', ' + shineAnimation + ' 2s ease-in-out infinite' : ''} `
      }
      pos="absolute"
      top="8px"
      right="16px"
      as={Link}
      to={routes.allBoosters}
      w="32px"
      h="32px"
      borderRadius="50%"
      opacity={0}
    >
      {!data?.canSpinToday && !isFetching && (
        <Box
          position="absolute"
          top="calc(100% - 2px)"
          left="50%"
          transform="translate(-50%, 0)"
          backgroundColor="#F83C3C"
          borderRadius="20px"
          color="white"
          fontSize="10px"
          w="fit-content"
          whiteSpace="nowrap"
          lineHeight="1.2"
          p="2px 4px"
          fontWeight="700"
          textShadow={`0 -1px 0px rgba(0, 0, 0, 0.3)`}
        >
          {buttonText}
        </Box>
      )}
      <BoostersIcon />
    </Box>
  );
};
