import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { Box, Flex, Grid, Img, Text, keyframes } from '@chakra-ui/react';
import { ContestParticipant } from 'models';
import routes from 'routes';

import UserBalance from 'components/profile/UserBalance';

import { useAppSelector } from 'hooks/app';

type SquadHeaderProps = {
  title: string;
  description: string;
  onClose?: () => void;
  participation: ContestParticipant;
  showTutorial?: () => void;
};

const fadeFromTopAnimation = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

const SquadHeader = ({ title, description, onClose, participation, showTutorial }: SquadHeaderProps) => {
  const isFakeUser = localStorage.getItem('is_fake_user') === 'true';
  const gridRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector((store) => store.auth.user);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);

  useEffect(() => {
    const portal = document.querySelector('.portal');

    const handleScroll = () => {
      const scrollPosition = portal ? portal.scrollTop : window.scrollY;

      if (scrollPosition > 60) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }
    };

    handleScroll();

    const scrollElement = portal || window;
    const eventName = portal ? 'scroll' : 'scroll';

    scrollElement.addEventListener(eventName, handleScroll);

    return () => {
      scrollElement.removeEventListener(eventName, handleScroll);
    };
  }, []);

  const rightSideAction = (
    <>
      {onClose && (
        <Box fontSize="14px" fontWeight="500" onClick={onClose} cursor="pointer">
          close
        </Box>
      )}
      {participation.status === 'completed' && !onClose && (
        <Box fontSize="14px" fontWeight="500" as={Link} to={routes.home}>
          close
        </Box>
      )}
      {!onClose && participation.status !== 'completed' && (
        <Box pos="absolute" right="0" width="max-content">
          <UserBalance user={user} />
        </Box>
      )}
    </>
  );

  return (
    <>
      {isHeaderFixed && (
        <Box
          w="48px"
          py="8px"
          px="12px"
          style={{
            height: `${gridRef.current?.offsetHeight}px`,
          }}
        />
      )}
      <Grid
        ref={gridRef}
        gridTemplateColumns="48px 1fr 48px"
        gap="8px"
        py="8px"
        px="12px"
        w="100%"
        background={isHeaderFixed ? '#242424' : 'transparent'}
        position={isHeaderFixed ? 'fixed' : 'relative'}
        top="0"
        left="0"
        zIndex="9999"
        animation={isHeaderFixed ? `${fadeFromTopAnimation} 0.2s ease-in-out forwards` : ''}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          {participation.status !== 'completed' && (
            <Box
              onClick={showTutorial}
              height="36px"
              width="36px"
              borderRadius="50%"
              backgroundColor="#111111"
              display="flex"
              alignItems="center"
              justifyContent="center"
              fontSize="20px"
            >
              ?
            </Box>
          )}
        </Box>
        <Box textAlign="center">
          <Text fontSize="16px" fontWeight="800" lineHeight="1.2">
            {title}
          </Text>
          <Text variant="text1" mt="4px">
            {description}
          </Text>
        </Box>
        <Flex position="relative" alignItems="center">
          {rightSideAction}
        </Flex>
      </Grid>
    </>
  );
};

export default SquadHeader;
