import { motion } from 'framer-motion';

const Leaf = ({ reverse }: { reverse?: boolean }) => {
  const leafStartingPositions = [
    { x: 10, y: 10 },
    { x: 8, y: 5 },
    { x: 5, y: 3 },
    { x: 8, y: 0 },
    { x: 8, y: -2 },
  ];

  const leafVariants = {
    hidden: (i: number) => ({
      opacity: 0,
      x: leafStartingPositions[i].x,
      y: leafStartingPositions[i].y,
    }),
    visible: (i: number) => ({
      opacity: 1,
      x: 0,
      y: 0,
      transition: {
        delay: (4 - i) * 0.05,
        duration: 0.2,
        ease: 'easeOut',
      },
    }),
  };

  return (
    <svg
      width="47"
      height="88"
      viewBox="0 0 47 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: reverse ? 'scaleX(-1)' : 'none' }}
    >
      <motion.path
        custom={4}
        variants={leafVariants}
        initial="hidden"
        animate="visible"
        d="M31.2658 75.2842C26.0942 76.6163 21.9411 79.7506 19.2769 83.9036C18.9635 84.4522 19.1202 85.1574 19.6687 85.4708C23.9785 87.8216 29.1502 88.6052 34.3218 87.3514C39.4935 86.0977 43.6465 82.885 46.3108 78.732C46.6242 78.1834 46.4675 77.4782 45.919 77.1648C41.6092 74.814 36.4375 73.9521 31.2658 75.2842Z"
        fill="#F83C3C"
      />
      <motion.path
        custom={3}
        variants={leafVariants}
        initial="hidden"
        animate="visible"
        d="M18.4905 61.6499C13.3972 60.2394 8.14708 60.8663 3.75898 63.1387C3.21047 63.4521 2.97539 64.1573 3.28883 64.7059C5.87467 68.9372 9.94933 72.2283 15.0427 73.6388C20.136 75.0492 25.3861 74.4224 29.7742 72.15C30.3227 71.8365 30.5577 71.1313 30.2443 70.5828C27.7368 66.4298 23.5838 63.1387 18.4905 61.6499Z"
        fill="#F83C3C"
      />
      <motion.path
        custom={2}
        variants={leafVariants}
        initial="hidden"
        animate="visible"
        d="M14.6531 43.3923C10.9702 39.5527 6.11199 37.5154 1.17538 37.2803C0.548512 37.2803 0 37.7504 0 38.3773C0.156718 43.3139 2.03733 48.2505 5.7202 52.0117C9.40307 55.773 14.2613 57.8887 19.1979 58.1237C19.8248 58.1237 20.3733 57.6536 20.3733 57.0267C20.2166 52.0901 18.336 47.1535 14.6531 43.3923Z"
        fill="#F83C3C"
      />
      <motion.path
        custom={1}
        variants={leafVariants}
        initial="hidden"
        animate="visible"
        d="M18.887 24.0383C17.5549 18.8666 14.4206 14.7136 10.2675 12.0494C9.71897 11.736 9.01374 11.8927 8.7003 12.4412C6.34954 16.7509 5.56595 21.9226 6.81969 27.0943C8.07343 32.266 11.2862 36.419 15.4392 39.0832C15.9877 39.3966 16.693 39.2399 17.0064 38.6914C19.4355 34.3817 20.2191 29.21 18.887 24.0383Z"
        fill="#F83C3C"
      />
      <motion.path
        custom={0}
        variants={leafVariants}
        initial="hidden"
        animate="visible"
        d="M33.6915 16.2795C35.102 11.1862 34.4751 5.93614 32.2027 1.54804C31.8893 0.999529 31.1841 0.764452 30.6355 1.07789C26.4042 3.66373 23.1131 7.73839 21.7026 12.8317C20.2922 17.925 20.919 23.1751 23.1914 27.5632C23.5049 28.1117 24.2101 28.3468 24.7586 28.0333C28.9116 25.5259 32.2027 21.4512 33.6915 16.2795Z"
        fill="#F83C3C"
      />
    </svg>
  );
};

export default Leaf;
