import { useCallback, useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';

import { Box, Fade, Flex, useToast } from '@chakra-ui/react';

import { api } from 'api/api';
import { useLazyGetUserQuery } from 'api/auth';
import { useLazyCheckSpinQuery, useLazyGetContestParticipantQuery, useResetContestMutation } from 'api/contest';

import { useAppSelector } from 'hooks/app';

import { setParticipation, setSelectedBooster } from 'store/slices/contest';
import { setReroll } from 'store/slices/reroll';

import { ReactComponent as LogoIcon } from 'icons/logo.svg';

import { textVariants } from './consts';

const Reroll = () => {
  const toast = useToast();
  const [textValue, setTextValue] = useState<string | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  const [isPageVisible, setIsPageVisible] = useState(true);
  const shownPhrases = useRef<Set<string>>(new Set());
  const currentContest = useAppSelector((state) => state.contest.contest);
  const dispatch = useDispatch();
  const [getUser, { data: user, isSuccess: isUserLoadedSuccesfully }] = useLazyGetUserQuery();
  const [checkSpin, { data: dailySpinStatus, isLoading: dailySpinStatusLoading, isSuccess: spinSuccessfullyChacked }] =
    useLazyCheckSpinQuery();
  const [
    resetContestMutation,
    { isSuccess: isContestResetSuccess, isLoading: isContestResetLoading, data: resetContest },
  ] = useResetContestMutation();
  const [
    getParticipation,
    {
      isLoading: isLoadingParticipant,
      isFetching: isFetchingisParticipant,
      isSuccess: isPartisipationLoadedSuccessfully,
    },
  ] = useLazyGetContestParticipantQuery();

  const animationComplete = useRef(false);

  const requestsComplete = useRef(false);

  const toastShown = useRef(false);

  useEffect(() => {
    if (resetContest) {
      dispatch(setParticipation(resetContest));
    }
  }, [resetContest]);

  const showToastAndChangePage = useCallback(() => {
    if (toastShown.current) return;

    toastShown.current = true;
    setIsPageVisible(false);

    setTimeout(() => {
      toast({
        duration: 3000,
        isClosable: true,
        position: 'bottom',
        render: ({ onClose }) => {
          return (
            <Flex
              alignItems="center"
              justifyContent="center"
              p="5px 18px !important"
              backgroundColor="#111111"
              borderRadius="10px"
              gap="10px"
              w="min-content"
              whiteSpace="nowrap"
              margin="0 auto 10px"
              onClick={onClose}
            >
              <Box> Cards rerolled</Box>
            </Flex>
          );
        },
      });
      ReactGA.event({
        category: 'Squad',
        action: 'squad rerolled successfully',
      });
      dispatch(setReroll({ reroll: false }));
    }, 500);
  }, [dispatch, toast]);

  const checkIfReadyToComplete = useCallback(() => {
    if (animationComplete.current && requestsComplete.current) {
      setTimeout(() => {
        showToastAndChangePage();
      }, 1000);
    }
  }, [showToastAndChangePage]);

  useEffect(() => {
    if (isContestResetSuccess && spinSuccessfullyChacked && isUserLoadedSuccesfully) {
      requestsComplete.current = true;
      checkIfReadyToComplete();
    }
  }, [isContestResetSuccess, spinSuccessfullyChacked, isUserLoadedSuccesfully, checkIfReadyToComplete]);

  useEffect(() => {
    if (currentContest) {
      resetContestMutation({ id: currentContest?.id });
      dispatch(setSelectedBooster(null));
    }
  }, []);

  useEffect(() => {
    if (isContestResetSuccess) {
      getUser();
      checkSpin();
    }
  }, [isContestResetSuccess]);

  useEffect(() => {
    const getPhrasesInOrder = () => {
      const category = 'default';
      const phrases: { [key: string]: string } = textVariants[category];
      const orderedPhrases: string[] = Object.values(phrases);

      if (Math.random() < 0.2 && textVariants['special']) {
        const specialPhrases = Object.values(textVariants['special']);
        const randomSpecial = specialPhrases[Math.floor(Math.random() * specialPhrases.length)];
        const randomPosition = Math.floor(Math.random() * (orderedPhrases.length + 1));
        orderedPhrases.splice(randomPosition, 0, randomSpecial);
      }
      return orderedPhrases.slice(0, 3);
    };

    const phrasesToShow = getPhrasesInOrder();
    let displayCount = 0;
    let timeoutIds: NodeJS.Timeout[] = [];

    const showNextPhrase = () => {
      setIsVisible(false);

      const timeoutId = setTimeout(() => {
        if (displayCount < phrasesToShow.length) {
          setTextValue(phrasesToShow[displayCount]);
          setIsVisible(true);

          if (displayCount < phrasesToShow.length - 1) {
            displayCount++;

            const delay = 1200;
            const nextTimeoutId = setTimeout(showNextPhrase, delay);
            timeoutIds.push(nextTimeoutId);
          } else {
            displayCount++;
            animationComplete.current = true;
            checkIfReadyToComplete();
          }
        }
      }, 300);

      timeoutIds.push(timeoutId);
    };

    if (phrasesToShow.length > 0) {
      setTextValue(phrasesToShow[0]);
      setIsVisible(true);
      displayCount = 1;

      if (phrasesToShow.length > 1) {
        const timeoutId = setTimeout(showNextPhrase, 1200);
        timeoutIds.push(timeoutId);
      } else {
        animationComplete.current = true;
        checkIfReadyToComplete();
      }
    }

    return () => {
      timeoutIds.forEach((id) => clearTimeout(id));
    };
  }, [checkIfReadyToComplete]);

  return (
    <Fade
      in={isPageVisible}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100dvh',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'var(--chakra-colors-bg)',
        flexDirection: 'column',
      }}
    >
      <Flex mt="8px" alignItems="center" justify="center" overflow="hidden" h="48px" zIndex="100" pos="relative">
        <Box>
          <LogoIcon />
        </Box>
      </Flex>
      <Flex
        gap="10px"
        flex="1"
        fontSize="28px"
        fontWeight="800"
        textAlign="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Fade in={isVisible}>{textValue}</Fade>
        <Box fontSize="16px" color="rgba(255,255,255,0.7)" fontWeight="700">
          you'll start over in a sec
        </Box>
      </Flex>
      <Flex
        fontSize="16px"
        lineHeight="1.2"
        fontWeight="700"
        textAlign="center"
        flexDirection="column"
        justifyContent="center"
        p="24px 60px"
      >
        reroll cost increases
        <br />
        within a contest
      </Flex>
    </Fade>
  );
};

export default Reroll;
