import { CSSProperties, useEffect, useState } from 'react';

import { Box, BoxProps, Flex, Image as Img, Text, css, keyframes } from '@chakra-ui/react';
import { Card, Contest, Player } from 'models';
import unidecode from 'unidecode';

import { useAppSelector } from 'hooks/app';

const gradientAnimation = keyframes`
  0% {
    rotate: 0deg;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    rotate: 360deg;
    opacity: 0;
  }
`;

interface FaceProps extends BoxProps {
  calcWidth: string;
  aspectRatio?: number;
  isOriginalImageUploaded: boolean;
  card?: Card | null;
  width?: string;
  detailed?: boolean;
  styles?: {
    image?: CSSProperties;
    info?: CSSProperties;
    firstName?: CSSProperties;
    lastName?: CSSProperties;
  };
  hasInjuryStatus?: boolean;
  contest?: Contest | null;
}

export const Face = (props: FaceProps) => {
  const {
    aspectRatio = 1 / 1,
    calcWidth,
    styles,
    isOriginalImageUploaded,
    card,
    detailed,
    hasInjuryStatus,
    contest,
  } = props;
  const leagueData = useAppSelector((state) => state.league.league);
  const [hasModifier, setHasModifier] = useState(false);

  useEffect(() => {
    if (card?.modifier) {
      setHasModifier(true);
    } else {
      setHasModifier(false);
    }
  }, [card]);

  const getLeagueBySrId = (srId?: string | null) => {
    if (!srId) return;
    return leagueData?.results.find((team) => {
      return team.sportradarId === srId;
    });
  };

  const resolveTeam = (() => {
    const teamName = card?.isHomeTeam
      ? getLeagueBySrId(card?.awayTeamSrId)?.name
      : getLeagueBySrId(card?.homeTeamSrId)?.name;
    return card?.isHomeTeam ? `vs ${teamName}` : `@ ${teamName}`;
  })();

  return (
    <>
      <Box
        style={styles?.image}
        backgroundPosition="center center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundImage={`url(${isOriginalImageUploaded ? card?.headshotThumbnails?.original : card?.headshotThumbnails?.small})`}
        width="100%"
        aspectRatio="1/1.1"
        borderRadius={`${8 * aspectRatio}px`}
        position="relative"
      >
        {hasInjuryStatus && (
          <Box
            position="absolute"
            bottom="0"
            left="50%"
            transform="translateX(-50%)"
            whiteSpace="nowrap"
            px="8px"
            h={detailed ? `${23 * aspectRatio}px` : '12px'}
            color="rgba(36, 36, 36, 1)"
            fontSize={detailed ? `${11 * aspectRatio}px` : '10px'}
            lineHeight="1"
            fontWeight="bold"
            fontFamily="JetBrains Mono"
            display="flex"
            textTransform="uppercase"
            alignItems="center"
            justifyContent="center"
            backgroundColor="rgba(255, 255, 0, 1)"
          >
            {detailed ? 'GAME-TIME DECISION' : 'GTD'}
          </Box>
        )}
      </Box>

      <Box textAlign="center" pt={`${7 * aspectRatio}px`} style={styles?.info}>
        {detailed ? (
          <>
            {/* <Text
              variant="firstName"
              fontSize={`${12 * aspectRatio}px`}
              textTransform="uppercase"
              textColor="inherit"
              color="#9B9292"
              style={styles?.firstName}
            >
              {unidecode(card?.playerFirstName || '')}
            </Text> */}
            <Text
              variant="lastName"
              style={styles?.lastName}
              fontSize={`${66 * aspectRatio}px`}
              lineHeight="1"
              textTransform="uppercase"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {unidecode(card?.playerLastName || '')}
            </Text>
            <Text
              textTransform="uppercase"
              variant="firstName"
              fontSize={`${13 * aspectRatio}px`}
              fontWeight="500"
              letterSpacing="4%"
              lineHeight="1"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              px="30px"
              mt={`${7 * aspectRatio}px`}
            >
              {resolveTeam}
            </Text>
          </>
        ) : (
          <Text
            variant="firstName"
            fontSize={`${16 * aspectRatio}px`}
            textTransform="uppercase"
            textColor="inherit"
            color="#9B9292"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            style={styles?.firstName}
            mt="1px"
          >
            {unidecode(card?.playerLastName || '')}
          </Text>
        )}
      </Box>

      <Flex
        align="center"
        justify="center"
        pos="absolute"
        textTransform="uppercase"
        top={`${10 * aspectRatio}px`}
        left={`${10 * aspectRatio}px`}
        fontSize={detailed ? `${14 * aspectRatio}px` : '8px'}
        fontWeight="500"
        borderRadius="50%"
        width={detailed ? `${28 * aspectRatio}px` : '18px'}
        height={detailed ? `${28 * aspectRatio}px` : '18px'}
        fontFamily="JetBrains Mono"
        color="#ffffff"
        bg="rgba(99, 88, 88, 1)"
        zIndex="1"
      >
        {card?.playerPrimaryPosition}
      </Flex>
      {hasModifier && card?.modifier?.multiplier ? (
        <Flex
          align="center"
          justify="center"
          pos="absolute"
          top={`${10 * aspectRatio}px`}
          right={`${10 * aspectRatio}px`}
          fontSize={detailed ? `${14 * aspectRatio}px` : '10px'}
          fontWeight="800"
          borderRadius={detailed ? `${12 * aspectRatio}px` : `${16 * aspectRatio}px`}
          px={`${6 * aspectRatio}px`}
          height={detailed ? `${28 * aspectRatio}px` : '18px'}
          lineHeight="1"
          color="#ffffff"
          backgroundColor="#F83C3C"
          textShadow={`0px 1px 1px rgba(0, 0, 0, 0.5)`}
          backgroundImage={
            'repeating-conic-gradient(from 0deg,rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4) , rgba(248, 60, 60, 1) 80deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4) 120deg)'
          }
          zIndex="1"
          overflow="hidden"
          boxShadow="inset 0 0px 0px 1px rgb(255, 255, 255, 0.1)"
        >
          +{((card?.modifier?.multiplier - 1) * 100).toFixed(0)}%
        </Flex>
      ) : null}
      {(card?.homeTeamSrId || card?.awayTeamSrId) && (
        <Flex
          align="center"
          justify="center"
          pos="absolute"
          top={`${10 * aspectRatio}px`}
          left={detailed ? `${28 * aspectRatio}px` : '20px'}
          fontSize={`${14 * aspectRatio}px`}
          width={detailed ? `${28 * aspectRatio}px` : '18px'}
          height={detailed ? `${28 * aspectRatio}px` : '18px'}
          backgroundImage={
            card?.isHomeTeam
              ? `url(${getLeagueBySrId(card?.homeTeamSrId)?.logo})`
              : `url(${getLeagueBySrId(card?.awayTeamSrId)?.logo})`
          }
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
        />
      )}
    </>
  );
};
