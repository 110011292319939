import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Box, Flex, Grid, Img, Portal } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Contest, ContestParticipant } from 'models';
import routes from 'routes';

import CardPlaceholder from 'components/builder/CardPlaceholder';
import Squad from 'components/builder/Squad/Squad';

import { ReactComponent as NoneIcon } from 'icons/none.svg';

import ParticipantPosition from './ParticipantPosition';

type Props = {
  participant: ContestParticipant;
  position: number;
  contest?: Contest;
};

const LeaderboardRow: FC<Props> = ({ participant, position, contest }) => {
  const [showParticipantDetail, setShowParticipantDetail] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  if (participant.status !== 'completed') {
    return null;
  }

  const contestId = searchParams.get('contestId');
  const friends = searchParams.get('friends') === 'true';

  const booster = participant.booster;
  const user = participant.user;

  const startCards = participant.cards.cardsWithStatus.filter((card) => card.status === 'start');
  const benchCard = participant.cards.cardsWithStatus.find((card) => card.status === 'bench');

  useEffect(() => {
    if (showParticipantDetail) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showParticipantDetail]);

  if (showParticipantDetail) {
    const date = dayjs(participant.updated).tz('America/Los_Angeles');
    const dateStr = date.format('MMM D');

    return (
      <Portal>
        <Squad
          participation={participant}
          cards={participant.cards.cardsWithStatus}
          pos="fixed"
          top="0"
          bottom="0"
          left="0"
          right="0"
          bg="var(--chakra-colors-bg)"
          onClose={() => setShowParticipantDetail(false)}
          title={`${participant.user.username}`}
          description={`Start Bench Cut · ${dateStr}`}
          overflow="auto"
          detailed
          zIndex="100"
          contestStatus={'completed'}
          contest={contest}
          className="portal"
        />
      </Portal>
    );
  }

  return (
    <>
      <Box
        p="16px 0"
        borderBottom="0.5px solid #FFFFFF4D"
        onClick={() => setShowParticipantDetail(true)}
        cursor="pointer"
        transition="all 0.2s ease-in-out"
        _active={{ backgroundColor: 'rgba(255,255,255,0.05)' }}
      >
        <Grid
          gap="10px"
          alignItems="flex-start"
          justifyContent="center"
          gridTemplateColumns="30px 310px"
          color={position === 0 ? '#FFD700' : 'white'}
        >
          <Flex align="end" minW="30px">
            <ParticipantPosition position={position + 1} />
          </Flex>
          <Flex
            gap="12px"
            align="flex-end"
            justifyContent="center"
            fontSize="14px"
            fontWeight="800"
            lineHeight="16.94px"
            flexShrink="0"
            flexGrow="0"
            flex="1"
            flexDirection="column"
          >
            <Flex flex="1" align="center" gap="8px" w="100%" justifyContent="space-between">
              <Flex
                gap="8px"
                flex="1"
                align="center"
                justify="flex-start"
                maxW="100%"
                minW="0"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`${routes.profile}/${user.id}`, {
                    state: {
                      from: `${routes.leaderboard}/?${contestId ? 'contestId=' + contestId : ''}&friends=${!!friends}`,
                    },
                  });
                }}
                borderRadius="12px"
                cursor="pointer"
                transition="all 0.2s ease-in-out"
                _active={{ backgroundColor: 'rgba(255,255,255,0.05)' }}
              >
                <Flex
                  w="36px"
                  h="36px"
                  borderRadius="12px"
                  background="#FFFFFF1A"
                  fontSize="16px"
                  fontWeight="800"
                  justify="center"
                  align="center"
                  color="#FFFFFFCC"
                  flexShrink="0"
                  textTransform="uppercase"
                  backgroundPosition="center center"
                  backgroundSize="cover"
                  backgroundImage={user.avatarUrl ? `url(${user.avatarUrl})` : undefined}
                  flex="0 0 36px"
                >
                  {user.avatarUrl ? null : user.username[0]}
                </Flex>
                <Box flex="1" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                  {user.username}
                </Box>
              </Flex>
              <Flex align="center" gap="2px" marginLeft="auto">
                {participant.totalPoints}
                <Img w="20px" h="20px" src="/coin.png" />
              </Flex>
            </Flex>

            <Flex
              justifyContent="flex-end"
              gridTemplateColumns="64px 64px 64px 64px 64px"
              gridColumnGap="0px"
              gridRowGap="-10px"
              w="100%"
            >
              {startCards.map((card) => (
                <CardPlaceholder
                  key={card.id}
                  minW="64px"
                  ml="-2px"
                  boxShadow="4px 0px 20px 0px #00000080"
                  card={card}
                />
              ))}
              {benchCard && (
                <CardPlaceholder
                  boxShadow="4px 0px 20px 0px #00000080"
                  bgColor="#4F4F4F"
                  color="#D2C9C9"
                  card={benchCard}
                  ml="-2px"
                />
              )}
              {booster ? (
                <Box
                  pos="relative"
                  w="64px"
                  h="64px"
                  backgroundColor={booster.backgroundColor ?? 'transparent'}
                  borderRadius="14px"
                  backgroundImage={`url(${booster.image})`}
                  backgroundSize="cover"
                  backgroundPosition="center center"
                  boxShadow="4px 0px 20px 0px #00000080"
                  overflow="hidden"
                  ml="-2px"
                >
                  {booster.icon && (
                    <Img
                      src={booster.icon}
                      pos="absolute"
                      top="50%"
                      left="50%"
                      w="64px"
                      h="64px"
                      objectFit="cover"
                      objectPosition="center center"
                      transform="translate(-50%, -50%)"
                    />
                  )}
                </Box>
              ) : (
                <Box
                  pos="relative"
                  ml="-2px"
                  w="64px"
                  h="64px"
                  backgroundColor="#111111"
                  borderRadius="14px"
                  boxShadow="4px 0px 20px 0px #00000080"
                >
                  <Box as={NoneIcon} pos="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" />
                </Box>
              )}
            </Flex>
          </Flex>
        </Grid>
      </Box>
    </>
  );
};

export default LeaderboardRow;
