import { defineStyleConfig } from '@chakra-ui/react';

const TextConfig = defineStyleConfig({
  baseStyle: {
    fontWeight: '800',
    color: 'white',
  },
  variants: {
    title: {
      fontSize: '28px',
      lineHeight: '31px',
    },
    text: {
      fontSize: '16px',
      lineHeight: '18px',
      opacity: '0.8',
    },
    text1: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '15.5px',
      color: '#FFFFFFCC',
    },
    text2: {
      fontFamily: 'JetBrains Mono',
      fontSize: '12px',
      fontWeight: '500',
      lineHeight: '15.5px',
      color: '#FFFFFF99',
    },
    hint: {
      fontSize: '14px',
      lineHeight: '17px',
      opacity: '0.6',
    },
    firstName: {
      fontFamily: 'JetBrains Mono',
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '1em',
      color: '#9B9292',
    },
    lastName: {
      fontFamily: 'Six Caps',
      fontSize: '64px',
      fontWeight: '400',
      lineHeight: '1em',
      color: '#9B9292',
    },
    boosterName: {
      fontFamily: 'JetBrains Mono',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '15.5px',
      color: '#242424',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    boosterMultiplier: {
      fontSize: '14px',
      fontWeight: '700',
      lineHeight: '16.8px',
      color: '#70632F',
      textAlign: 'center',
    },
    pageTitle: {
      minHeight: '48px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: '800',
      fontSize: '18px',
      lineHeight: '21.6px',
      color: 'white',
      textTransform: 'lowercaser',
    },
    tutorialTitle: {
      fontSize: '32px',
      lineHeight: '1.1',
      color: 'white',
      fontWeight: '800',
      textAlign: 'center',
    },
    tutorialText: {
      fontSize: '19px',
      lineHeight: '1.3',
      color: 'white',
      fontWeight: '500',
      textAlign: 'center',
      textWrap: 'balance',
    },
    tutorialPhase: {
      fontSize: '16px',
      lineHeight: '1.3',
      color: 'white',
      fontWeight: '500',
      textAlign: 'center',
      listSpacing: '12px',
      fontFamily: 'JetBrains Mono',
    },
  },
  defaultProps: {
    variant: 'title',
  },
});

export default TextConfig;
