export const feedbacks = [
  { user: 'bron2goat', phrase: 'you made me care about random Tuesday games' },
  { user: 'firejoe414', phrase: 'built different. fast, chaotic in the best way' },
  { user: 'fwest6864', phrase: 'Absolute W game got me addicted fr.' },
  { user: 'bvlly', phrase: "fantasy that don't feel like homework" },
  { user: 'trevork', phrase: 'game taught me what betrayal feels like' },
  { user: 'adamriley', phrase: 'idc about winning i just lOVE clicking stuff' },
  { user: 'jason_043', phrase: "booster hit. squad hit. i'm HIM now. thanks SBC" },
  { user: 'Sigma', phrase: "ain't going back to spreadsheet fantasies" },
  { user: 'ohaiyo', phrase: "rerolled 3 times just to get Kuzma, i'm in hell" },
  { user: 'MELO', phrase: 'bro had 40 last night, i START him and he drops 6' },
  { user: 'eye_test_failed', phrase: "i hate this game. see y'all tomorrow" },
  { user: 'jayfrombk', phrase: "cut just dropped 42 PTS. i'm dead." },
  { user: 'tylermade', phrase: 'draft like a genius, score like a clown' },
  { user: 'noah_xo', phrase: 'fresh' },
  { user: 'mikeyclutch', phrase: 'sbc my daily ritual now' },
  { user: 'paulnuval12', phrase: 'I love the game and how your adding on to it' },
  { user: 'bucketsbaby', phrase: 'feels like fantasy tinder' },
  { user: 'dainter.jmt', phrase: 'my ball knowledge has gotten soo much better lol' },
  { user: 'CavsGirl45', phrase: 'How do I add my son as a friend?' },
  { user: 'jackeb2011', phrase: 'wish I could play more than one a day' },
  { user: 'Oakleyrichardson2', phrase: 'Please make this an app' },
  { user: 'cooperscott2705', phrase: 'I really just like the simplicity' },
];
