import { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { Box, Button, Fade, Flex, SlideFade, Spinner, Text, keyframes, useToast } from '@chakra-ui/react';
import dayjs from 'dayjs';
import routes from 'routes';

import InviteForm from 'components/InviteForm';
import { Loader } from 'components/Loader';
import Squad from 'components/builder/Squad/Squad';
import Tutorial from 'components/pages/BuilderPage/ui/Tutorial';

import { useAppSelector } from 'hooks/app';
import useBoosters from 'hooks/useBoosters';
import useBuilder from 'hooks/useBuilder';
import useContest from 'hooks/useContest';

import { selectSelectedBooster } from 'store/slices/contest';
import { setReroll } from 'store/slices/reroll';

import { ReactComponent as ClutchpointsIcon } from 'icons/clutchpoints.svg';

import NoBoosterSubmitModal from './NoBoosterSubmitModal';

const bounceAnimation = keyframes`
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-18px);}
	60% {transform: translateY(-7px);}
`;

interface SquadPageProps {
  updateUserData: () => void;
}

const SquadPage = ({ updateUserData }: SquadPageProps) => {
  const [showTutorial, setShowTutorial] = useState(false);
  const [show, setShow] = useState(true);
  const [showNoBoosterModal, setShowNoBoosterModal] = useState(false);
  const contest = useAppSelector((store) => store.contest.contest);
  const participation = useAppSelector((store) => store.contest.participation);
  const [showReferralForm, setShowReferralForm] = useState(false);
  const { fetchParticipation, isLoading: isParticipationLoading } = useContest();
  const { submitBooster, isLoading: isBoostersLoading, fetchBoosters, allBoosters } = useBoosters({ contest });
  const {
    completeSquad,
    cardsWithStatus,
    isLoading: isCompleteParticipantLoading,
    isSuccess: isCompleteParticipantSuccess,
  } = useBuilder({ contest, participation });

  const isLoading = isParticipationLoading || isBoostersLoading;

  useEffect(() => {
    if (contest && !participation) {
      fetchParticipation(contest);
    }
    if (contest && !allBoosters) {
      fetchBoosters();
    }
  }, []);

  const refFormRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const toast = useToast();
  const user = useAppSelector((store) => store.auth.user);
  const selectedBooster = useAppSelector(selectSelectedBooster);

  useEffect(() => {
    if (participation?.status === 'completed' && user?.showReferralForm && isCompleteParticipantSuccess) {
      setTimeout(() => {
        setShowReferralForm(true);
      }, 1000);
    }
  }, [isCompleteParticipantSuccess, participation?.status, user?.showReferralForm]);

  const setTutorialVisibility = () => {
    const lastShown = localStorage.getItem('sbc_tutorial_2');
    const today = new Date();

    if (lastShown) {
      const lastShownDate = new Date(lastShown);
      const diffTime = Math.abs(today.getTime() - lastShownDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays >= 3) {
        setShowTutorial(true);
        localStorage.setItem('sbc_tutorial_2', today.toDateString());
      }
    } else {
      setShowTutorial(true);
      localStorage.setItem('sbc_tutorial_2', today.toDateString());
    }
  };

  const handleTutorialToggle = () => {
    setShowTutorial((prev) => !prev);
  };

  const handleScrollToRefForm = () => {
    refFormRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const submitSquad = async () => {
    if (!participation) {
      return;
    }
    ReactGA.event({
      category: 'Squad',
      action: 'submit squad',
    });
    try {
      await submitBooster({ participantId: participation?.id });
      await completeSquad();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (participation) {
      updateUserData();
      // fetchBoosters();
    }
  }, [participation]);

  if (isLoading) {
    return <Loader />;
  }

  if (contest?.status === 'locked' || contest?.status === 'completed') {
    return <Navigate to={routes.leaderboard} replace />;
  }

  if (contest?.status !== 'active') {
    return <Navigate to={routes.home} replace />;
  }

  if (!participation) {
    return null;
  }

  if (participation?.status === 'cards_pending') {
    return <Navigate to={routes.builder} replace />;
  }

  const submitted = participation.status === 'completed';

  if (!submitted) {
    setTutorialVisibility();
  }

  return (
    <>
      <Fade in={show}>
        {!submitted && (
          <Fade
            unmountOnExit
            in={showTutorial}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 99990,
            }}
          >
            <Tutorial
              onOk={handleTutorialToggle}
              title={
                <Flex alignItems="center" justifyContent="center" flexDirection="column" gap="12px">
                  <Text variant="tutorialPhase">SBC 101</Text>
                  <Text variant="tutorialTitle">boost and submit</Text>
                </Flex>
              }
              content={
                <Flex flexDirection="column" gap="28px">
                  <Text variant="tutorialText">
                    ⚡ <b>Use a boost</b> to max your score
                  </Text>
                  <Text variant="tutorialText">
                    🎲 <b>Reroll cards</b> if squad feels off
                  </Text>
                  <Text variant="tutorialText">
                    ⚔️ <b>Submit when ready.</b> Results drop after games.
                  </Text>
                </Flex>
              }
            />
          </Fade>
        )}

        <Box pos="relative" overflow="hidden">
          <Squad
            participation={participation}
            cards={cardsWithStatus}
            pos="relative"
            title={submitted ? `${user?.username}` : 'Build your squad'}
            description={
              submitted
                ? `Start Bench Cut · ${dayjs(contest.startsAt).tz('America/Los_Angeles').format('MMM D')}`
                : 'Earn from total PTS and boosts'
            }
            contestStatus={contest.status}
            showTutorial={handleTutorialToggle}
            contest={contest}
            boosters={allBoosters}
          />

          {participation.status === 'completion_pending' && (
            <Flex
              pos="fixed"
              bottom="20px"
              left="50%"
              transform="translateX(-50%)"
              zIndex="100"
              gap="10px"
              w="100%"
              maxW="500px"
              px="15px"
            >
              <Button
                flex="1"
                onClick={async () => {
                  ReactGA.event({
                    category: 'Squad',
                    action: 'reroll squad',
                  });
                  setShow(false);
                  setTimeout(() => {
                    dispatch(setReroll({ reroll: true }));
                  }, 500);
                }}
                isDisabled={!!(!!participation?.rerollPrice > !!user?.sbcTotalPoints && user?.sbcRerollCount === 0)}
                display="flex"
                flexDirection="column"
                background="rgba(255, 81, 0, 1)"
                boxShadow="0px -4px 0px 0px #00000080 inset, 0 0 50px 0 rgba(255, 81, 0, 0.25)"
                _hover={{ background: 'rgba(255, 81, 0, 0.8)' }}
                _active={{
                  background: 'rgba(255, 81, 0, 1)',
                  pt: '2px',
                  mt: '2px',
                  h: '58px',
                  boxShadow: '0px -2px 0px 0px #00000080 inset, 0px 0px 20px 0px #F83C3C40',
                }}
                _focus={{
                  background: 'rgba(255, 81, 0, 1)',
                }}
                _disabled={{
                  background: 'rgba(255, 81, 0, 1)',
                  cursor: 'not-allowed',
                  opacity: 0.5,
                }}
              >
                <Box>reroll cards</Box>
                <Box fontSize="11px">
                  {!!user?.sbcRerollCount
                    ? `${user?.sbcRerollCount} free left`
                    : `for ${participation.rerollPrice} coins`}
                </Box>
              </Button>
              <Button
                flex="1"
                onClick={async () => {
                  if (selectedBooster || !allBoosters || allBoosters.length === 0) {
                    submitSquad();
                  }
                  if (!selectedBooster && allBoosters && allBoosters.length > 0) {
                    setShowNoBoosterModal(true);
                  }
                }}
                isDisabled={isLoading}
              >
                submit
              </Button>
            </Flex>
          )}

          <Flex flexDirection="column" alignItems="center" justifyContent="center" gap="12px">
            <SlideFade
              in={isCompleteParticipantLoading}
              unmountOnExit
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
              offsetY="0px"
              transition={{ enter: { duration: 0.5 }, exit: { duration: 0.3 } }}
            >
              <Box padding="32px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Spinner />
              </Box>
            </SlideFade>

            <SlideFade
              in={showReferralForm}
              unmountOnExit
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
              offsetY="20px"
              transition={{ enter: { duration: 0.5, delay: 0.2 }, exit: { duration: 0.3 } }}
            >
              <Box
                marginTop="16px"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap="16px"
                width="100%"
              >
                <Flex flexDirection="column" alignItems="center" justifyContent="center" gap="12px">
                  <Box
                    pos="relative"
                    w="24px"
                    h="24px"
                    animation={`${bounceAnimation} 2s infinite both`}
                    _after={{
                      content: '""',
                      width: '2px',
                      height: '11px',
                      backgroundColor: 'white',
                      position: 'absolute',
                      top: 'calc(50% - 0px)',
                      left: 'calc(50% + 0.5px)',
                      transform: 'translate(100%, -50%) rotate(45deg)',
                      borderRadius: '2px',
                    }}
                    _before={{
                      content: '""',
                      width: '2px',
                      height: '11px',
                      backgroundColor: 'white',
                      position: 'absolute',
                      top: 'calc(50% - 0px)',
                      right: 'calc(50% + 0.5px)',
                      transform: 'translate(-100%, -50%) rotate(-45deg)',
                      borderRadius: '2px',
                    }}
                  />
                  <Box
                    onClick={handleScrollToRefForm}
                    as="span"
                    fontSize="18px"
                    lineHeight="1.2"
                    fontWeight="800"
                    textAlign="center"
                    style={{ textWrap: 'balance' }}
                  >
                    Compete with friends and rule the leaderboard
                  </Box>
                </Flex>

                <InviteForm ref={refFormRef} />
              </Box>
            </SlideFade>
          </Flex>

          <Box as={ClutchpointsIcon} display="block" mx="auto" mt="32px" mb="24px" />
        </Box>
      </Fade>
      <NoBoosterSubmitModal
        show={showNoBoosterModal}
        onClose={() => {
          setShowNoBoosterModal(false);
        }}
        onSubmit={submitSquad}
      />
    </>
  );
};

export default SquadPage;
