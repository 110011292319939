import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import camelcaseKeys from 'camelcase-keys';
import decamelizeKeys from 'decamelize-keys';
import Cookies from 'js-cookie';
import { League, PaginationResponse, Team } from 'models';

const baseUrl = process.env.REACT_APP_API_URL || 'https://clutchpoints-users-test.4taps.me/';

type CustomBaseQueryExtraOptions = {
  disableCamelcase?: boolean;
};

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  CustomBaseQueryExtraOptions
> = async (args, api, extraOptions) => {
  const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = Cookies.get('sbc_access_token');

      if (token) {
        headers.set('Authorization', `Token ${token}`);
      }

      return headers;
    },
  });

  if (typeof args === 'object' && extraOptions?.disableCamelcase !== false) {
    if (args.params) {
      args.params = decamelizeKeys(args.params, { deep: true });
    }

    if (args.body) {
      args.body = decamelizeKeys(args.body, { deep: true, exclude: ['avatarUrl'] });
    }
  }

  const result = await baseQuery(args, api, extraOptions);
  if (result.data) {
    result.data = camelcaseKeys(result.data as Record<string, unknown>, {
      deep: true,
    });
  }

  return result;
};

export const api = createApi({
  tagTypes: ['User', 'History', 'ContestParticipant'],
  reducerPath: 'api',
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    getLeague: builder.query<PaginationResponse<Team>, { league?: League }>({
      query: ({ league = 'nba' }) => ({
        url: `https://clutchpoints-middlepoint.4taps.me/api/core/teams/?league=${league}`,
      }),
    }),
  }),
});

export const { useGetLeagueQuery } = api;
