import { FC, useEffect, useState } from 'react';

import { Fade, Flex, Text } from '@chakra-ui/react';

import { useLazyCheckSpinQuery } from 'api/contest';

import { Loader } from 'components/Loader';

import { useAppSelector } from 'hooks/app';
import useBuilder from 'hooks/useBuilder';
import useContest from 'hooks/useContest';

import Builder from './ui/Builder';
import Spinner from './ui/Spinner/Spinner';
import Tutorial from './ui/Tutorial';

const BuilderPage: FC = () => {
  const [isSpinOpen, setIsSpinOpen] = useState<boolean | null>(null);

  const [showTutorial, setShowTutorial] = useState(false);
  const contest = useAppSelector((state) => state.contest.contest);
  const participation = useAppSelector((state) => state.contest.participation);

  const { fetchParticipation } = useContest();

  useEffect(() => {
    if (contest && !participation) {
      fetchParticipation(contest);
    }
  }, []);

  const { cardsWithStatus, selectedCards, card, benchCard, chooseCard, animation, isAnimating } = useBuilder({
    contest,
    participation,
  });

  const [checkSpin, { data: dailySpinStatus, isLoading: dailySpinStatusLoading }] = useLazyCheckSpinQuery();

  useEffect(() => {
    const lastShown = localStorage.getItem('sbc_tutorial_1');
    const today = new Date();

    if (lastShown) {
      const lastShownDate = new Date(lastShown);
      const diffTime = Math.abs(today.getTime() - lastShownDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays >= 3) {
        setShowTutorial(true);
        localStorage.setItem('sbc_tutorial_1', today.toDateString());
      }
    } else {
      setShowTutorial(true);
      localStorage.setItem('sbc_tutorial_1', today.toDateString());
    }
  }, []);

  useEffect(() => {
    if (isSpinOpen === null) {
      if (dailySpinStatus) {
        setIsSpinOpen(dailySpinStatus.canSpinToday);
      } else {
        checkSpin();
      }
    }
  }, [dailySpinStatus, isSpinOpen, setIsSpinOpen]);

  const handleTutorialToggle = () => {
    setShowTutorial((prev) => !prev);
  };

  if (participation === null || isSpinOpen === null) {
    return <Loader />;
  }

  return (
    <>
      {isSpinOpen !== null && (
        <>
          {isSpinOpen && <Spinner onClose={() => setIsSpinOpen(false)} dailySpinStatus={dailySpinStatus} />}
          {!isSpinOpen && (
            <Fade
              unmountOnExit
              in={showTutorial}
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 999,
              }}
            >
              <Tutorial
                onOk={handleTutorialToggle}
                title={
                  <Flex alignItems="center" justifyContent="center" flexDirection="column" gap="12px">
                    <Text variant="tutorialPhase">SBC 101</Text>
                    <Text variant="tutorialTitle">build squad</Text>
                  </Flex>
                }
                content={
                  <Flex flexDirection="column" gap="28px">
                    <Text variant="tutorialText">
                      🏀 <b>7 players</b> from today’s games
                    </Text>
                    <Text variant="tutorialText">
                      🃏 <b>Start 3, cut 3, bench 1</b> backup
                    </Text>
                    <Text variant="tutorialText">
                      🎯 <b>Rank up, get coins</b> based on squad’s real-game scores
                    </Text>
                  </Flex>
                }
              />
            </Fade>
          )}

          <Builder
            cardsWithStatus={cardsWithStatus}
            selectedCards={selectedCards}
            card={card}
            contest={contest}
            benchCard={benchCard}
            chooseCard={chooseCard}
            animation={animation}
            participation={participation}
            isAnimating={isAnimating}
            showTutorial={handleTutorialToggle}
          />
        </>
      )}
    </>
  );
};

export default BuilderPage;
