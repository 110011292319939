import { Booster, Contest } from 'models';

import {
  useGetParticipantBoostersQuery,
  useLazyGetParticipantBoostersQuery,
  useSetParticipantBoosterMutation,
} from 'api/contest';

import { selectSelectedBooster, setParticipation, setSelectedBooster } from 'store/slices/contest';

import { useAppDispatch, useAppSelector } from './app';

interface UseBoostersProps {
  contest: Contest | null;
}

const useBoosters = ({ contest }: UseBoostersProps) => {
  const [fetchBoosters, { data: boosters, isLoading: isBoostersLoading }] = useLazyGetParticipantBoostersQuery();
  const isLoading = isBoostersLoading;
  const filteredBoosters = boosters?.filter((booster) => booster.league === contest?.league && !booster.usedAt) ?? [];

  const [setBoosterMutation, { isLoading: isSetBoosterLoading }] = useSetParticipantBoosterMutation();

  const dispatch = useAppDispatch();

  const setBooster = async (booster: Booster) => {
    if (isSetBoosterLoading) {
      return;
    }
    dispatch(setSelectedBooster(booster));
  };

  const selectedBooster = useAppSelector(selectSelectedBooster);

  const submitBooster = async ({ participantId }: { participantId: number }) => {
    if (isSetBoosterLoading || !selectedBooster) {
      return;
    }
    const { data, error } = await setBoosterMutation({
      participantId: participantId,
      boosterId: selectedBooster.id,
    });
    if (data) {
      dispatch(setParticipation(data));
    }
  };

  return {
    boosters: filteredBoosters,
    allBoosters: boosters,
    isLoading,
    setBooster,
    selectedBooster,
    submitBooster,
    fetchBoosters,
  };
};

export default useBoosters;
