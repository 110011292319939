import { FC, useCallback } from 'react';
import ReactGA from 'react-ga4';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { Box, BoxProps, Button, Fade, Flex, FormControl, Grid, Image, Input, SlideFade, Text } from '@chakra-ui/react';

import { useSignInMutation } from 'api/auth';

import FeedbackSlider from 'components/feedback-slider/FeedbackSlider';

import SocialSignIn from './SocialSignIn';

type FormData = {
  email: string;
};

const SignIn: FC<BoxProps> = (props) => {
  const [signIn, signInReq] = useSignInMutation();

  const {
    handleSubmit,
    register,
    setError,
    getValues,
    formState: { errors, isValid },
  } = useForm<FormData>();
  const email = getValues().email;

  const onSubmit = useCallback(
    async ({ email }: FormData) => {
      ReactGA.event({ category: 'User', action: 'Submit sign-in form' });
      const { error } = await signIn({
        email,
        redirectUri: window.location.origin,
      });
      if (error) {
        if ('originalStatus' in error && error.originalStatus === 500) {
          setError('email', { message: 'something’s wrong' });
        } else {
          setError('email', { message: 'something’s wrong with an email' });
        }
      } else {
        // Cookies.set('sbc_access_token', data.accessToken)
        // dispatch(setUser(data))
        // dispatch(setIsAuthicated(true))
      }
    },
    [signIn]
  );

  if (signInReq.isSuccess) {
    ReactGA.event({ category: 'User', action: 'Sign-in success' });
    return (
      <Fade
        in
        transition={{ enter: { duration: 0.5 }, exit: { duration: 0.5 } }}
        style={{
          width: '100%',
          height: '100%',
          minHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          gap: '12px',
          zIndex: 2,
        }}
      >
        <Box fontSize="24px" fontWeight="800" lineHeight="29px" letterSpacing="-2%">
          Sent you a sign-in link.
          <br />
          Tap it and you’re in.
        </Box>
        <Box fontSize="14px" fontWeight="500" opacity="0.8">
          Do it once, we’ll remember your device.
          <br />
          👉 {email}
        </Box>

        <SlideFade
          in
          offsetY="20px"
          transition={{ enter: { duration: 0.5, delay: 0.6 }, exit: { duration: 0.5 } }}
          style={{ zIndex: 2, marginTop: '28px' }}
        >
          <Image src="home/sbc_magic-link_notification.png" alt="board row" w="100%" maxW="355px" px="" mx="auto" />
        </SlideFade>
      </Fade>
    );
  }

  return (
    <Flex
      w="100%"
      h="100%"
      minH="100%"
      pb="20px"
      pt="8px"
      flexDirection="column"
      justifyContent="center"
      textAlign="center"
      gap="32px"
    >
      <Box pos="relative">
        <Box as="form" {...props} onSubmit={handleSubmit(onSubmit)} data-cy="signin-form">
          <Flex textAlign="center" flexDirection="column" gap="8px">
            <Text variant="title" lineHeight="1.2" letterSpacing="-2%">
              Welcome to SBC
            </Text>
            <Text fontSize="16px" fontWeight="700" lineHeight="1.2" letterSpacing="-2%" style={{ textWrap: 'balance' }}>
              Sign in to track squads, save your grind, and clash for the prizes. Free to play.
            </Text>
          </Flex>
          <Flex flexDir="column" gap="16px" mt="24px" alignItems="stretch">
            <FormControl isInvalid={!!errors.email}>
              <Input
                placeholder="email address"
                type="email"
                autoComplete="email"
                data-cy="email-input"
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: 'Invalid Email',
                  },
                })}
              />
            </FormControl>
            <Button type="submit" isDisabled={!isValid} isLoading={signInReq.isLoading} data-cy="submit-button">
              next
            </Button>
            {!!errors.email?.message && (
              <Box color="brand.500" textAlign="center" h="28px">
                {errors.email?.message}
              </Box>
            )}
          </Flex>
        </Box>
      </Box>
      <SocialSignIn />
      <FeedbackSlider />

      <Text
        justifySelf="end"
        mt="auto"
        bottom="20px"
        left="0"
        right="0"
        variant="text2"
        textAlign="center"
        maxW="310px"
        mx="auto"
        sx={{
          '& a': {
            color: 'white',
          },
        }}
      >
        By tapping "Next" or using our services, you agree to our{' '}
        <Box as={Link} to="https://clutchpoints.com/terms-of-service">
          Terms
        </Box>{' '}
        and{' '}
        <Box as={Link} to="https://clutchpoints.com/privacy-policy">
          Privacy Policy
        </Box>
        .
      </Text>
      <Image
        src="home/sbc_magic-link_notification.png"
        alt="board row"
        w="1px"
        h="1px"
        pos="absolute"
        top="0"
        left="0"
      />
    </Flex>
  );
};

export default SignIn;
