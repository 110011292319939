import { Card } from './types';

export const playerCards: Card[] = [
  { src: 'home/card_builder_6.webp', status: 'start' },
  { src: 'home/card_builder_5.webp', status: 'cut' },
  { src: 'home/card_builder_4.webp', status: 'cut' },
  { src: 'home/card_builder_3.webp', status: 'start' },
  { src: 'home/card_builder_2.webp', status: 'cut' },
  { src: 'home/card_builder_1.webp', status: 'start' },
  { src: 'home/card_builder_0.webp', status: 'bench' },
];
