import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PaginationResponse, Team } from 'models';

type LeagueState = {
  league: PaginationResponse<Team> | null;
};

const initialState: LeagueState = {
  league: null,
};

export const leagueSlice = createSlice({
  name: 'league',
  initialState,
  reducers: {
    setLeague(state, action: PayloadAction<LeagueState>) {
      state.league = action.payload.league;
    },
  },
  selectors: {
    selectLeague: (state: LeagueState) => state.league,
  },
});

export default leagueSlice.reducer;

export const { setLeague } = leagueSlice.actions;

export const { selectLeague } = leagueSlice.selectors;
