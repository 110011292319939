import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Flex, Image, Text, keyframes } from '@chakra-ui/react';

import Header from './Header';

const fadeInAnimation = keyframes`
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const fadeAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
`;

const infiniteBackgroundSlide = keyframes`
  from {
    background-position: 0 center;
  }
  to {
    background-position: -160vw center;
  }
`;

const PrizesPage = () => {
  const [animationDuration, setAnimationDuration] = useState(6);
  const navigate = useNavigate();
  const onButtonClick = () => {
    navigate('/leaderboard/monthly/?friends=false');
  };

  const changeAnimationDuration = () => {
    if (window.innerWidth < 768) {
      setAnimationDuration(6);
    } else if (window.innerWidth < 1024) {
      setAnimationDuration(9);
    } else if (window.innerWidth < 1280) {
      setAnimationDuration(12);
    } else if (window.innerWidth < 1440) {
      setAnimationDuration(15);
    } else if (window.innerWidth < 1920) {
      setAnimationDuration(18);
    }
  };

  useEffect(() => {
    changeAnimationDuration();
  }, []);

  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center" gap="20px" overflow="hidden" p="0 0 20px">
      <Header />
      <Flex flexDirection="column" alignItems="center" gap="16px" marginBottom="16px">
        <Text
          fontSize="28px"
          fontWeight="800"
          lineHeight="1.2"
          textAlign="center"
          css={{ textWrap: 'balance' }}
          opacity="0"
          animation={`${fadeInAnimation} 0.5s ease-out forwards 0.1s`}
        >
          Play SBC and win some
        </Text>
        <Text
          fontSize="16px"
          lineHeight="1.2"
          fontWeight="700"
          textAlign="center"
          css={{ textWrap: 'balance' }}
          opacity="0"
          animation={`${fadeInAnimation} 0.5s ease-out forwards 0.2s`}
        >
          Finish #1 in March monthly leaderboard and win the CLUTCH quiet basketball
        </Text>
      </Flex>

      <Flex position="relative" justifyContent="center" alignItems="center" flexDirection="column" width="100%">
        <Box
          top="0%"
          left="0%"
          position="absolute"
          w="100%"
          h="100%"
          boxShadow="inset 0 40px 40px -30px #242424, inset 0 -40px 20px -20px #242424"
          animation={`${infiniteBackgroundSlide} ${animationDuration}s linear infinite`}
          backgroundRepeat="repeat"
          backgroundSize="80%"
          opacity="0.5"
          style={{
            backgroundImage: "url('/sbc-logo-pattern.svg'), url('/sbc-logo-pattern.svg')",
          }}
        />
        <Box position="relative" zIndex="1" w="250px" aspectRatio="1/1" py="15px">
          <Image src="/promo-ball.webp" alt="clutch-quiet-basketball" position="relative" zIndex="1" />
          <Box
            position="absolute"
            top="61px"
            left="5px"
            zIndex="2"
            backgroundColor="rgba(248, 60, 60, 1)"
            borderRadius="20px"
            p="5px 11px 6px"
            lineHeight="1.3"
            fontWeight="700"
            fontSize="16px"
            transform="rotate(-7deg)"
          >
            march
          </Box>
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            boxShadow="0 0 200px 0 rgba(248, 60, 60, 1)"
            w="80px"
            h="80px"
            borderRadius="50%"
          />
        </Box>
      </Flex>
      <Button onClick={onButtonClick}>leaderboard</Button>
      <Flex flex="1" alignItems="flex-end">
        <Text
          fontSize="12px"
          lineHeight="1.2"
          fontWeight="500"
          textAlign="center"
          css={{ textWrap: 'balance' }}
          opacity="0"
          animation={`${fadeAnimation} 0.5s ease-out forwards 0.3s`}
        >
          Winner gets decided at the end of period. Prize is 100% discount on respective item at ClutchPoints Store
        </Text>
      </Flex>
    </Flex>
  );
};

export default PrizesPage;
