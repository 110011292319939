export const textVariants = {
  default: {
    one: 'disbanding squad',
    two: 'shuffling players',
    three: 'praying to rng gods',
  },
  special: {
    one: 'selling the team',
    two: 'moving to vegas',
    three: 'hiring a new mascot',
  },
};
