import { FC, useCallback, useEffect } from 'react';
import AppleSignin from 'react-apple-signin-auth';
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';

import { Box, BoxProps, Flex, Text, useToast } from '@chakra-ui/react';
import FacebookLogin from '@greatsumini/react-facebook-login';
import Cookies from 'js-cookie';
import { parseJWT } from 'utils/JWTencode';

import { useLazyGetUserQuery, useSignInAppleMutation, useSignInFBMutation, useSignInGoogleMutation } from 'api/auth';

import { setIsAuthicated, setUser } from 'store/slices/auth';

import { ReactComponent as AppleIcon } from 'icons/apple.svg';
import { ReactComponent as FbIcon } from 'icons/fb.svg';
import { ReactComponent as GoogleIcon } from 'icons/google.svg';

const fbAppId = '1075306005823517';
const appleClientId = 'com.clutchpoints.ClutchPoints2';
const googleClientId = '609550829109-m5gdf8j2f264bk47dm4kqplhjnucichj.apps.googleusercontent.com';

const SocialSignIn: FC<BoxProps> = (props) => {
  const toast = useToast();
  useEffect(() => {
    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = initializeGoogleSignIn;
      document.body.appendChild(script);
    };

    const initializeGoogleSignIn = () => {
      (window as any).google.accounts.id.initialize({
        client_id: googleClientId,
        callback: handleCredentialResponse,
      });
    };

    loadGoogleScript();
  }, []);

  const [getUserMutation] = useLazyGetUserQuery();
  const dispatch = useDispatch();

  const onSuccess = useCallback(async (token: string) => {
    Cookies.set('sbc_access_token', token, { secure: true, expires: 60 * 60 * 24 * 7 });
    ReactGA.event({
      category: 'User',
      action: 'Sign-in by social button success',
    });
    const { data, error } = await getUserMutation();
    if (!error) {
      dispatch(setIsAuthicated(!!data));
      dispatch(setUser(data));
      toast({
        duration: 3000,
        isClosable: true,
        position: 'bottom',
        render: ({ onClose }) => {
          return (
            <Flex
              alignItems="center"
              justifyContent="center"
              p="5px 18px !important"
              backgroundColor="#111111"
              borderRadius="10px"
              gap="10px"
              w="min-content"
              whiteSpace="nowrap"
              margin="0 auto 10px"
              onClick={onClose}
            >
              <Box>Successfully signed in</Box>
            </Flex>
          );
        },
      });
    }
  }, []);

  const [signInApple] = useSignInAppleMutation();
  const [signInFB] = useSignInFBMutation();
  const [signInGoogle] = useSignInGoogleMutation();

  const handleCredentialResponse = async (response: any) => {
    const { data, error } = await signInGoogle({
      credential: response.credential,
    });
    if (!error) {
      onSuccess(data.token);
    }
  };

  const openGooglePopup = () => {
    (window as any).google.accounts.id.prompt((notification: any) => {
      // if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
      //   toast({
      //     duration: 3000,
      //     isClosable: true,
      //     position: 'bottom',
      //     render: ({ onClose }) => {
      //       return (
      //         <Flex
      //           alignItems="center"
      //           justifyContent="center"
      //           p="5px 18px !important"
      //           backgroundColor="#111111"
      //           borderRadius="10px"
      //           gap="10px"
      //           w="min-content"
      //           whiteSpace="nowrap"
      //           margin="0 auto 10px"
      //           onClick={onClose}
      //         >
      //           <Box>Something went wrong, please try again</Box>
      //         </Flex>
      //       );
      //     },
      //   });
      // }
    });
  };

  return (
    <Box {...props}>
      <Box>
        <Text textAlign="center" variant="text1">
          or quick access with
        </Text>
        <Flex mt="16px" justify="center" gap="16px">
          <GoogleIcon
            width="54px"
            height="54px"
            data-cy="google-auth-button"
            cursor="pointer"
            onClick={openGooglePopup}
          />

          <AppleSignin
            data-cy="apple-auth-button"
            uiType="light"
            authOptions={{
              clientId: appleClientId,
              scope: 'email name',
              redirectURI: window.location.origin + '/',
              state: 'state',
              nonce: 'nonce',
              usePopup: true,
            }}
            noDefaultStyle={false}
            onSuccess={async (response?: any) => {
              const appleData = parseJWT(response.authorization.id_token);
              const { data, error } = await signInApple({
                id: appleData.payload.sub,
                identityToken: response.authorization.id_token,
              });
              if (!error) {
                onSuccess(data.accessToken);
              }
            }}
            onError={(error?: Error) => {
              toast({
                duration: 3000,
                isClosable: true,
                position: 'bottom',
                render: ({ onClose }) => {
                  return (
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      p="5px 18px !important"
                      backgroundColor="#111111"
                      borderRadius="10px"
                      gap="10px"
                      w="min-content"
                      whiteSpace="nowrap"
                      margin="0 auto 10px"
                      onClick={onClose}
                    >
                      <Box>Something went wrong, please try again</Box>
                    </Flex>
                  );
                },
              });
              console.error(error);
            }}
            render={(props: any) => <AppleIcon cursor="pointer" width="54px" height="54px" {...props} />}
          />

          <FacebookLogin
            appId={fbAppId}
            autoLoad={false}
            fields="name,email,picture"
            onSuccess={async (response) => {
              const { data, error } = await signInFB({
                accessToken: response.accessToken,
                facebookId: response.userID,
              });
              if (!error) {
                onSuccess(data.accessToken);
              }
            }}
            onFail={(error) => {
              toast({
                duration: 3000,
                isClosable: true,
                position: 'bottom',
                render: ({ onClose }) => {
                  return (
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      p="5px 18px !important"
                      backgroundColor="#111111"
                      borderRadius="10px"
                      gap="10px"
                      w="min-content"
                      whiteSpace="nowrap"
                      margin="0 auto 10px"
                      onClick={onClose}
                    >
                      <Box>Something went wrong, please try again</Box>
                    </Flex>
                  );
                },
              });
              console.error('Login Failed!', error);
            }}
            render={(renderProps) => (
              <FbIcon
                cursor="pointer"
                data-cy="facebook-auth-button"
                width="54px"
                height="54px"
                onClick={renderProps.onClick}
              />
            )}
          />
        </Flex>
      </Box>
    </Box>
  );
};

export default SocialSignIn;
