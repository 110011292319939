import { Link, Navigate, useLocation } from 'react-router-dom';

import { Box, Flex, Grid } from '@chakra-ui/react';
import routes from 'routes';

import SignIn from 'components/auth/SignIn';
import SocialSignIn from 'components/auth/SocialSignIn';

import { useAppSelector } from 'hooks/app';

import { ReactComponent as LeftArrowIcon } from 'icons/back-arr.svg';

const SignInPage = () => {
  const isAuthicated = useAppSelector((store) => store.auth.isAuthicated);
  const location = useLocation();
  let backLink = '/';

  if (location.state && location.state.from) {
    backLink = location.state.from;
  }
  if (isAuthicated) {
    return <Navigate to={routes.home} replace />;
  }
  return (
    <>
      <Grid
        gridTemplateColumns="48px 1fr auto"
        gap="8px"
        pos="sticky"
        top="0"
        zIndex="1"
        w="100%"
        maxW="500px"
        mx="auto"
        mb="auto"
        transition="all 0.2s ease-in-out"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box
            as={Link}
            to={backLink}
            height="48px"
            width="48px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontSize="20px"
            borderRadius="12px"
            transition="all 0.2s ease-in-out"
            _active={{
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            }}
          >
            <Box as={LeftArrowIcon} />
          </Box>
        </Box>
        <Flex textAlign="center" justifyContent="center" alignItems="center"></Flex>

        <Flex />
      </Grid>
      <Flex px="24px" alignItems="center" justifyContent="center" flexDirection="column" gap="16px">
        <SignIn pos="relative" width="100%" />
      </Flex>
    </>
  );
};

export default SignInPage;
