import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import api from 'api';

import auth from './slices/auth';
import contest from './slices/contest';
import league from './slices/league';
import reroll from './slices/reroll';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth,
    contest,
    league,
    reroll,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
