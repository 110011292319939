import { FC, useEffect, useRef, useState } from 'react';
// import ReactDOM from 'react-dom';
import { Navigate } from 'react-router-dom';
import SlotCounter from 'react-slot-counter';

import { Box, Button, Fade, Flex, Grid, Text, keyframes } from '@chakra-ui/react';
// import html2canvas from 'html2canvas';
import routes from 'routes';

import CardPlaceholder from 'components/builder/CardPlaceholder';
import PlayerCard from 'components/builder/player-card/PlayerCard';
import { BuilderProps } from 'components/pages/BuilderPage/types';

import { ReactComponent as LogoIcon } from 'icons/logo-bg.svg';

interface CustomHTMLDivElement extends HTMLDivElement {
  disintegrated?: boolean;
}

const pulseAnimation = keyframes`
0% {
    filter: none;
  }
  95% {
    filter: none;
  }
  95% {
    filter: drop-shadow(0 1px 0 rgba(255, 0, 0, 1));
  }
  100% {
    filter: drop-shadow(0 1px 0 rgba(255, 0, 0, 1));
  }
`;

const cutterAnimation = keyframes`
0% {
    transform: translate(0px, 0px) rotate(45deg) scale(0.5);
    left: 0;
    opacity: 0.4;
  }
    40% {
    transform: translate(0%, 0px) rotate(45deg) scale(1.1);
    filter: blur(2px);
  }
  99% {
    transform: translate(0%, 0px) rotate(45deg) scale(.8);
    left: 100%;
    opacity: 1;
  }
  100% {
    transform: translate(0%, 0px) rotate(45deg) scale(.8);
    left: 100%;
    opacity: 0;
  }
`;

const Builder: FC<BuilderProps> = ({
  cardsWithStatus,
  selectedCards,
  card,
  contest,
  benchCard,
  chooseCard,
  animation,
  participation,
  isAnimating,
  showTutorial,
}) => {
  const [restCardsLength, setRestCardsLength] = useState<number>(7);
  const playerCardRef = useRef<CustomHTMLDivElement>(null);
  const cutBlockRef = useRef<CustomHTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setRestCardsLength((num) => 7 - cardsWithStatus.length);
  }, [cardsWithStatus]);

  const canCut = restCardsLength > 3 - selectedCards.length + (benchCard ? 0 : 1);
  const isDisabled = !card || isAnimating;

  // use it in the future
  // const handleCaptureClick = async () => {
  //   if (playerCardRef.current) {
  //     playerCardRef.current.style.setProperty('transform', 'translate(0, 0) rotate(0deg) scale(1)', 'important');
  //     const canvas = await html2canvas(playerCardRef.current, {
  //       backgroundColor: null,
  //       allowTaint: true,
  //       useCORS: false,
  //     });

  //     // Create two canvases for the top and bottom halves
  //     const canvas1 = document.createElement('canvas');
  //     const canvas2 = document.createElement('canvas');
  //     const ctx1 = canvas1.getContext('2d');
  //     const ctx2 = canvas2.getContext('2d');

  //     const width = canvas.width;
  //     const height = canvas.height / 2;

  //     canvas1.width = width;
  //     canvas1.height = height;
  //     canvas2.width = width;
  //     canvas2.height = height;

  //     // Draw the top half of the image on canvas1
  //     ctx1?.drawImage(canvas, 0, 0, width, height, 0, 0, width, height);

  //     // Draw the bottom half of the image on canvas2
  //     ctx2?.drawImage(canvas, 0, height, width, height, 0, 0, width, height);

  //     canvas1.style.setProperty('position', 'absolute', 'important');
  //     canvas1.style.setProperty('top', '0%', 'important');
  //     // canvas1.style.setProperty('left', '50%', 'important');
  //     // canvas1.style.setProperty('transform', 'translate(-50%, -50%)', 'important');
  //     // canvas1.style.setProperty('height', '100%', 'important');
  //     canvas1.style.setProperty('width', 'calc(100% - 18px)', 'important');
  //     canvas1.style.setProperty('background', 'none', 'important');
  //     canvas1.style.setProperty('animation', 'cutCardTop 1s ease-in-out forwards', 'important');

  //     canvas2.style.setProperty('position', 'absolute', 'important');
  //     canvas2.style.setProperty('bottom', '0%', 'important');
  //     // canvas2.style.setProperty('left', '50%', 'important');
  //     // canvas2.style.setProperty('transform', 'translate(-50%, -50%)', 'important');
  //     // canvas2.style.setProperty('height', '100%', 'important');
  //     canvas2.style.setProperty('width', 'calc(100% - 18px)', 'important');
  //     canvas2.style.setProperty('background', 'none', 'important');
  //     canvas2.style.setProperty('animation', 'cutCardBottom 1s ease-in-out forwards', 'important');

  //     cutBlockRef.current?.appendChild(canvas1);
  //     cutBlockRef.current?.appendChild(canvas2);

  //     const container = document.createElement('div');
  //     container.style.setProperty('position', 'absolute', 'important');
  //     container.style.setProperty('top', '50%', 'important');
  //     container.style.setProperty('left', '50%', 'important');
  //     container.style.setProperty('transform', 'translate(-50%, -50%)', 'important');
  //     container.style.setProperty('height', '40px', 'important');
  //     container.style.setProperty('width', '100%', 'important');
  //     ReactDOM.render(createCutter(), container);
  //     cutBlockRef.current?.appendChild(container);

  //     setTimeout(() => {
  //       canvas1.remove();
  //       canvas2.remove();
  //       container.remove();
  //       playerCardRef.current?.style.removeProperty('transform');
  //     }, 1000);
  //   }
  // };

  // const createCutter = () => {
  //   return (
  //     <>
  //       {Array.from({ length: 20 }).map((_, i) => (
  //         <Box
  //           style={{
  //             position: 'absolute',
  //             background: 'red',
  //             borderRadius: '0',
  //           }}
  //           width={`calc(20px - ${i * 0.5}px)`}
  //           height={`calc(20px - ${i * 0.5}px)`}
  //           animation={`${cutterAnimation} 0.2s forwards ${i * 0.003}s`}
  //         />
  //       ))}
  //     </>
  //   );
  // };

  // const handleCutCard = () => {
  //   handleCaptureClick();

  //   if (!isDisabled) {
  //     chooseCard(card, 'cut');
  //   }
  // };

  useEffect(() => {
    if (card) {
      const timer = setTimeout(() => {
        setIsVisible(!!card);
      }, 300);
      return () => clearTimeout(timer);
    } else {
      setIsVisible(false);
    }
  }, [card]);

  if (contest && contest.status !== 'active') {
    return <Navigate to={routes.home} replace />;
  }

  if (participation && participation?.status !== 'cards_pending') {
    return <Navigate to={routes.squad} replace />;
  }

  if (!participation) {
    return null;
  }

  const getRefPositionRelativeToViewport = (ref: React.RefObject<HTMLElement>) => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      return {
        top: rect.top,
        left: rect.left,
        right: rect.right,
        bottom: rect.bottom,
        width: rect.width,
        height: rect.height,
        x: rect.x,
        y: rect.y,
      };
    }
    return null;
  };

  const playerCardPosition = getRefPositionRelativeToViewport(playerCardRef);

  return (
    <Fade in style={{ padding: '8px 12px', overflow: 'hidden' }}>
      <Grid gridTemplateRows="auto 1fr auto auto" h="100%">
        <Grid gridTemplateColumns="48px 1fr 48px" gap="8px" py="8px">
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box
              onClick={showTutorial}
              height="36px"
              width="36px"
              borderRadius="50%"
              backgroundColor="#111111"
              display="flex"
              alignItems="center"
              justifyContent="center"
              fontSize="20px"
            >
              ?
            </Box>
          </Box>
          <Box textAlign="center">
            <Text fontSize="16px" fontWeight="800" lineHeight="1.2">
              Build your squad
            </Text>
            <Text variant="text1" mt="4px">
              Earn from total PTS and boosts
            </Text>
          </Box>
          <Box></Box>
        </Grid>
        {/* {playerCardPosition && (
          <Box
            css={{
              opacity: 0,
              transition: 'filter 0.6s',
              position: 'absolute',
              top: playerCardPosition?.top,
              left: playerCardPosition?.left,
              width: playerCardPosition?.width,
              height: playerCardPosition?.height,
              borderRadius: '16px',
              background: '#F83C3C',
              filter: 'blur(16px)',
            }}
          />
        )} */}

        <Box pos="relative" overflow="hidden">
          <Box
            pos="absolute"
            pointerEvents="none"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            as={LogoIcon}
          />
          <Fade
            in={isVisible}
            style={{
              padding: '16px 0',
              position: 'relative',
              height: '100%',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            {card && (
              <PlayerCard
                ref={playerCardRef}
                detailed
                card={card}
                height="100%"
                animation={`${animation} 0.6s forwards`}
                contest={contest}
              />
            )}
            {/* <Flex
              ref={cutBlockRef}
              py="16px"
              height="calc(100% - 32px)"
              aspectRatio="1/1.5"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              pos="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              zIndex="2"
            /> */}
          </Fade>
        </Box>
        <Flex flexDirection="column" backgroundColor="#242424">
          <Flex
            justify="space-between"
            textAlign="center"
            textTransform="uppercase"
            margin="11px -12px 0"
            px="12px"
            zIndex="4"
          >
            <Box>
              <Text mb="12px" variant="text2" color="white">
                CARDS
              </Text>
              <Text fontWeight="500" fontFamily="JetBrains Mono" lineHeight="1.1" fontSize="40px" pt="5px">
                <SlotCounter value={restCardsLength} sequentialAnimationMode useMonospaceWidth />
              </Text>
            </Box>
            <Box>
              <Text mb="12px" variant="text2" color="white">
                STARTERS
              </Text>
              <Flex gap="8px">
                <CardPlaceholder showAnimation card={selectedCards[0]} />
                <CardPlaceholder showAnimation card={selectedCards[1]} />
                <CardPlaceholder showAnimation card={selectedCards[2]} />
              </Flex>
            </Box>
            <Box>
              <Text mb="12px" variant="text2" color="white">
                BENCH
              </Text>
              <CardPlaceholder showAnimation card={benchCard} />
            </Box>
          </Flex>
          <Flex justify="center" gap="8px" py="24px" px="12px" zIndex="5" margin="0 -12px 0">
            <Button
              flex="1"
              isDisabled={selectedCards.length >= 3}
              data-cy="start-button"
              onClick={() => {
                if (!isDisabled) {
                  chooseCard(card, 'start');
                  if (playerCardRef.current) {
                    playerCardRef.current.style.opacity = '1';
                  }
                }
              }}
            >
              start
            </Button>
            <Button
              flex="1"
              isDisabled={!!benchCard}
              data-cy="bench-button"
              onClick={() => {
                if (!isDisabled) {
                  chooseCard(card, 'bench');
                  if (playerCardRef.current) {
                    playerCardRef.current.style.opacity = '1';
                  }
                }
              }}
            >
              bench
            </Button>
            <Button
              flex="1"
              isDisabled={!canCut}
              data-cy="cut-button"
              onClick={() => {
                if (!isDisabled) {
                  chooseCard(card, 'cut');
                }
              }}
            >
              cut
            </Button>
          </Flex>
        </Flex>
      </Grid>
    </Fade>
  );
};

export default Builder;
