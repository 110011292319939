import { FC, useEffect, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Flex, Img } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Contest } from 'models';
import routes from 'routes';
import { formatDuration } from 'utils/formatDuration';

import { useLazyGetContestParticipantQuery } from 'api/contest';

import { getEventStatusString } from './utils';

const SliderItem: FC<{ contest: Contest }> = ({ contest, ...props }) => {
  const navigate = useNavigate();
  const title = useMemo(() => {
    if (!contest) return '';
    if (contest.displayName) return contest.displayName;
    const startsAtNewYork = dayjs(contest.startsAt).tz('America/Los_Angeles');
    const timeOfDay = startsAtNewYork.hour() >= 18 ? 'Night' : 'Day';
    const formattedDate = startsAtNewYork.format('M/D');
    return `${contest.league.toUpperCase()} ${timeOfDay} ${formattedDate}`;
  }, [contest]);

  const statusTime = useMemo(() => {
    if (!contest) return '';
    return getEventStatusString(contest);
  }, [contest]);

  const onButtonClick = (location: string, message: string | undefined) => {
    ReactGA.event({
      category: 'Contest',
      action: 'click',
      label: message,
    });

    navigate(location);
  };

  return (
    <Box display="flex" justifyContent="center" flexDir="column" {...props}>
      <Box textAlign="center">
        <Img mx="auto" src={contest.image} width="180px" />
        <Box
          textTransform="lowercase"
          fontSize="14px"
          fontWeight="800"
          lineHeight="1.2"
          mt="8px"
          color={contest.status !== 'scheduled' ? '#F83C3C' : 'white'}
        >
          {statusTime}
        </Box>
        <Box fontSize="24px" fontWeight="800" lineHeight="1.2" mt="8px">
          {title}
        </Box>
        <Box fontSize="14px" fontWeight="500" lineHeight="1.3" mt="8px" color="rgba(255, 255, 255, 0.9)">
          {contest.modifier
            ? `${contest.modifier?.name}: ${contest.modifier?.description}`
            : 'SBC Classics: Squads and boosters, nothing fancy'}
        </Box>
        <Flex justify="center" mt="23px">
          <ContestButton contest={contest} onButtonClick={onButtonClick} />
        </Flex>
      </Box>
    </Box>
  );
};

export default SliderItem;

const ContestButton = ({
  contest,
  onButtonClick,
}: {
  contest: Contest;
  onButtonClick: (location: string, message: string | undefined) => void;
}) => {
  const [trigger, { data: participant, isLoading }] = useLazyGetContestParticipantQuery();

  useEffect(() => {
    if (contest.id && (contest.status === 'active' || contest.status === 'locked')) {
      trigger(contest.id);
    }
  }, []);

  const resolveButtonText = (status: string) => {
    switch (status) {
      case 'completed':
        return 'leaderboard';
      case 'scheduled':
        return 'build squad';
      case 'locked':
        const text = (() => {
          if (participant?.status === 'completion_pending') {
            return 'my squad';
          } else if (participant?.status === 'completed') {
            return 'my squad';
          } else if (participant?.status === 'cards_pending') {
            return 'my squad';
          } else {
            return 'build squad';
          }
        })();
        return text;
      case 'active':
        if (participant?.status === 'cards_pending' || participant?.status === 'completion_pending') {
          if (localStorage.getItem('is_fake_user') === 'true') {
            return 'build squad';
          }
          return 'finish squad';
        }
        return participant?.status === 'completed' ? 'my squad' : 'build squad';
      default:
        return '';
    }
  };

  const getButtonProps = () => {
    const buttonProps = {
      text: resolveButtonText(contest.status),
      onClick: () => {},
      isDisabled: true,
      dataCy: '',
    };

    switch (contest.status) {
      case 'completed':
        buttonProps.onClick = () =>
          onButtonClick(`${routes.leaderboard}?contestId=${contest.id}&friends=false`, 'Leaderboard click');
        buttonProps.isDisabled = false;
        buttonProps.dataCy = 'completed-contest-btn';
        break;
      case 'scheduled':
        buttonProps.isDisabled = true;
        break;
      case 'locked':
        buttonProps.onClick = () => onButtonClick(routes.squad, 'My Squad Click On Locked Contest');
        buttonProps.isDisabled = participant ? false : true;
        buttonProps.dataCy = 'locked-contest-btn';
        break;
      case 'active':
        buttonProps.onClick = () => onButtonClick(routes.squad, 'My Squad Click On Active Contest');
        buttonProps.isDisabled = false;
        buttonProps.dataCy = 'active-contest-btn';
        break;
      default:
        break;
    }

    return buttonProps;
  };

  const { text, onClick, isDisabled, dataCy } = useMemo(getButtonProps, [contest, participant]);

  return (
    <Button w="205px" onClick={onClick} isDisabled={isDisabled || isLoading} data-cy={dataCy} isLoading={isLoading}>
      {text}
    </Button>
  );
};
