import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Box, Image, keyframes } from '@chakra-ui/react';
import routes from 'routes';

const bounceAnimation = keyframes`
  0% {
    bottom: 0px;
  }
  
  100% {
    bottom: 10px;
  }
`;

const PromoSticker = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  return (
    <Box
      as={Link}
      to={routes.prizes}
      position="fixed"
      bottom="16px"
      left="16px"
      zIndex="1000"
      w="90px"
      h="90px"
      transform="rotate(-2.12deg) "
      opacity={imageLoaded ? 1 : 0}
      transition="opacity 0.3s ease"
    >
      <Box
        position="absolute"
        bottom="18px"
        left="2px"
        zIndex="2"
        backgroundColor="rgba(248, 60, 60, 1)"
        borderRadius="20px"
        p="2px 8px 4px"
        lineHeight="1"
        fontWeight="700"
        fontSize="12px"
        transform="rotate(-6deg)"
      >
        prizes
      </Box>
      <Box
        animation={`${bounceAnimation} 2s ease-out infinite`}
        position="relative"
        zIndex="1"
        style={{
          animationDirection: 'alternate',
        }}
      >
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          boxShadow="0 0 30px 0 rgba(248, 60, 60, 1)"
          w="60px"
          h="60px"
          borderRadius="50%"
        />
        <Image src="/promo-ball.webp" alt="promo" pos="relative" zIndex="1" onLoad={handleImageLoad} />
      </Box>
    </Box>
  );
};

export default PromoSticker;
