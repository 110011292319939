import { useCallback, useEffect, useState } from 'react';

import { Box, Fade, Flex, Image as Img, SlideFade, Text, keyframes } from '@chakra-ui/react';
import { use } from 'chai';

import { Loader } from 'components/Loader';

import { playerCards } from './fixtures';
import RunningLine from './running-line';
import { Card } from './types';

const fadeIn = keyframes`
  0% { opacity: 0; transform: translateY(10px); }
  100% { opacity: 1; transform: translateY(0); }
`;

const shimmer = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

const startAnimation = keyframes`
0% {
    transform: translate(0px, 0px) rotate(0deg) scale(1);
    box-shadow: 0px 10px 10px -5px rgba(0,0,0,1);
  }
  100% {
    transform: translate(-200%, 0px) rotate(-20deg) scale(.8);
    box-shadow: 40px 40px 60px -5px rgba(0,0,0,0.5);
    opacity: 0.5;
  }
`;

const cutAnimation = keyframes`
0% {
    transform: translate(0px, 0px) rotate(0deg) scale(1);
    box-shadow: 0px 10px 10px -5px rgba(0,0,0,1);
  }
  100% {
    transform: translate(200%, 0px) rotate(20deg) scale(.8);
    box-shadow: 40px 40px 60px -5px rgba(0,0,0,0.5);
    opacity: 0.5;
  }
`;

const textAnimation = keyframes`
 0% {
  opacity: 0;
    transform: translateY(0px) scale(0.5) rotate(-10deg);
  }
 
  20% {
    opacity: 0.5;
    transform: translateY(0px) scale(0.7) rotate(10deg);
  }
   
    40% {
    opacity: 1;
    transform: translateY(0px) scale(1) rotate(-10deg);
  }
  
    60% {
    opacity: 1;
    transform: translateY(0px) scale(1) rotate(10deg);
  }
   
    80% {
    opacity: 1;
    transform: translateY(0px) scale(1) rotate(-10deg);
  }

    100% {
    opacity: 1;
    transform: translateY(0px) scale(1) rotate(0deg);
  }
`;

const textFadeOutAnimation = keyframes`
 0% {
    opacity: 1;
    transform: translateY(0px) scale(1) rotate(0deg);
  }
  100% {
    opacity: 0;
    transform: translateY(-30px) scale(1) rotate(10deg);
  }
`;

const Banner = ({ onImagesLoad }: { onImagesLoad?: () => void }) => {
  const [currentCard, setCurrentCard] = useState<Card | null>(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const preloadImages = useCallback((images: string[], callback: () => void) => {
    let loadedCount = 0;
    const totalImages = images.length;

    if (totalImages === 0) {
      callback();
      return;
    }

    images.forEach((src) => {
      const img = new Image();

      img.onload = img.onerror = () => {
        loadedCount++;
        if (loadedCount === totalImages) {
          callback();
        }
      };

      img.src = src;
    });
  }, []);

  useEffect(() => {
    const imageSources = playerCards.map((card) => card.src);

    preloadImages(imageSources, () => {
      setImagesLoaded(true);
      if (playerCards.length > 0) {
        setCurrentCard(playerCards[0]);
      }
    });
  }, [preloadImages]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentCard) {
        const currentIndex = playerCards.findIndex((card) => card.src === currentCard.src);
        const nextIndex = (currentIndex + 1) % playerCards.length;
        setCurrentCard(playerCards[nextIndex]);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [currentCard]);

  useEffect(() => {
    if (imagesLoaded && onImagesLoad) {
      onImagesLoad();
    }
  }, [imagesLoaded, onImagesLoad]);

  return (
    <Flex justifyContent="center" alignItems="center" position="relative" overflow="hidden" pb="40px" mb="-20px">
      <Box position="absolute" w="calc(100% + 40px)" top="40px" left="-20px" zIndex="1" transform="rotate(-2deg)">
        <RunningLine />
      </Box>

      <SlideFade
        offsetY={20}
        in={!imagesLoaded}
        transition={{
          enter: { duration: 0 },
          exit: { duration: 0 },
        }}
        unmountOnExit
        style={{ zIndex: 2 }}
      >
        <Box
          w="220px"
          aspectRatio="2/3"
          boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
          borderRadius="16px"
          animation={`${shimmer} 1.5s infinite linear, ${fadeIn} 0.4s ease forwards`}
          backgroundImage="linear-gradient(90deg,rgba(240, 240, 240, 0.09) 25%,rgba(224, 224, 224, 0.03) 50%,rgba(240, 240, 240, 0.1) 75%)"
          backgroundColor="#242424"
          backgroundSize="200% 100%"
          pos="relative"
          zIndex="2"
          mb="6px"
        />
      </SlideFade>

      <SlideFade
        offsetY={20}
        in={imagesLoaded && !!currentCard}
        transition={{ enter: { duration: 0.3, delay: 0.3 }, exit: { duration: 0.3 } }}
        unmountOnExit
        style={{ zIndex: 2 }}
      >
        <Box key={currentCard?.src} animation={`${fadeIn} 0.4s ease forwards`} position="relative" zIndex="2">
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            zIndex="20"
            fontSize="24px"
            fontWeight="800"
          >
            <Text
              fontSize="28px"
              fontWeight="500"
              color="#fff"
              textAlign="center"
              textTransform="uppercase"
              fontFamily="JetBrains Mono"
              letterSpacing="2%"
              whiteSpace="nowrap"
              opacity="0"
              animation={`${textAnimation} 0.4s 1.1s ease forwards, ${textFadeOutAnimation} 0.4s 1.7s ease forwards`}
              transform="translateY(0)"
            >
              {currentCard?.status}
            </Text>
          </Box>
          <Img
            animation={`${currentCard?.status === 'cut' ? cutAnimation : startAnimation} 0.8s 1s ease forwards`}
            src={currentCard?.src}
            backgroundColor="#fff"
            borderRadius="16px"
            alt="Player Card"
            width="220px"
            aspectRatio="2/3"
            boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
          />
        </Box>
      </SlideFade>

      <Box
        position="absolute"
        w="calc(100% + 40px)"
        top="265px"
        left="-20px"
        zIndex="1"
        transform="rotate(5deg)"
        opacity="0.5"
      >
        <RunningLine direction="right" />
      </Box>

      {playerCards.map((card, index) => (
        <Img
          src={card.src}
          key={index + card.src + card.status}
          position="absolute"
          w="2px"
          h="2px"
          top="0"
          left="0"
          opacity="0"
        />
      ))}
    </Flex>
  );
};

export default Banner;
