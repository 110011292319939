import { useCallback, useEffect, useRef } from 'react';

import { Contest, ContestParticipant } from 'models';

import {
  useCreateContestParticipantMutation,
  useGetContestQuery,
  useLazyGetContestParticipantQuery,
  useResetContestMutation,
} from 'api/contest';

import { setContestResponse, setParticipation } from 'store/slices/contest';

import { useAppDispatch, useAppSelector } from './app';

const useContest = () => {
  const dispatch = useAppDispatch();
  const { previousContest, contest, nextContest } = useAppSelector((store) => store.contest);
  const currentParticipation = useAppSelector((store) => store.contest.participation);
  const {
    isLoading: isLoadingContest,
    isFetching: isFetchingContest,
    data,
    error,
  } = useGetContestQuery(undefined, { skip: !!contest });

  const [
    getParticipation,
    {
      data: participation,
      isLoading: isLoadingParticipant,
      isFetching: isFetchingisParticipant,
      isError: isGetParticipationError,
      isSuccess: isGetParticipationSuccess,
    },
  ] = useLazyGetContestParticipantQuery();

  const [
    createParticipationMutation,
    { data: createdParticipation, isLoading: isCreateParticipation, isSuccess: isParticipationCreateSuccess },
  ] = useCreateContestParticipantMutation();

  const createParticipation = useCallback(async (contest: Contest) => {
    await createParticipationMutation(contest.id);
  }, []);

  const fetchParticipation = useCallback(async (contest: Contest) => {
    if (contest?.status !== 'active') {
      return;
    }
    await getParticipation(contest.id, false);
  }, []);

  useEffect(() => {
    if (isGetParticipationError && contest) {
      createParticipation(contest);
    }
  }, [isGetParticipationError, contest]);

  useEffect(() => {
    if (isGetParticipationSuccess && participation) {
      dispatch(setParticipation(participation));
    }
    if (isParticipationCreateSuccess && createdParticipation) {
      dispatch(setParticipation(createdParticipation));
    }
  }, [isGetParticipationSuccess, participation, isParticipationCreateSuccess, createdParticipation]);

  const [resetContestMutation, { isSuccess: isContestResetSuccess, isLoading: isContestResetLoading }] =
    useResetContestMutation();

  useEffect(() => {
    if (isLoadingParticipant || !data) return;
    if (error) {
      console.error(error);
    }
    dispatch(setContestResponse(data));
  }, [isLoadingParticipant, data, error]);

  const isLoading = isLoadingContest || isLoadingParticipant || isCreateParticipation;
  const isFetching = isFetchingContest || isFetchingisParticipant;

  return {
    participation: !isLoading && contest ? currentParticipation : null,
    previousContest: !isLoading ? previousContest : null,
    contest: !isLoading ? contest : null,
    nextContest,
    isLoading,
    isFetching,
    error,
    fetchParticipation,
    setParticipation: (participation: ContestParticipant | null) => {
      dispatch(setParticipation(participation));
    },
    resetContest: resetContestMutation,
    isContestResetSuccess,
    isContestResetLoading,
  };
};
export default useContest;
