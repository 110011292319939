import { Box, Flex, Img } from '@chakra-ui/react';
import { User } from 'models';
import { nFormatter } from 'utils/formatNumberWithCommas';

const UserBalance = ({ user }: { user: User | undefined }) => {
  return (
    <Flex
      backgroundColor="rgba(255, 255, 255, 0.1)"
      fontSize="14px"
      fontWeight="800"
      boxShadow="inset 0 0 0 1px rgba(255,255,255,0.1)"
      borderRadius="8px"
      p="4px 4px 4px 7px"
      alignItems="center"
      lineHeight="1"
      gap="2px"
    >
      <Box textShadow="0 1px 1px rgba(0,0,0,0.5)">{user?.sbcTotalPoints ? nFormatter(user.sbcTotalPoints, 1) : 0}</Box>{' '}
      <Img w="16px" src="/coin.webp" />
    </Flex>
  );
};

export default UserBalance;
