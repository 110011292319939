import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Fade } from '@chakra-ui/react';
import routes from 'routes';

import { useGetParticipantBoostersQuery, useLazyCheckSpinQuery, useLazyGetParticipantBoostersQuery } from 'api/contest';

import Boosters from 'components/booster/Boosters';

import Spinner from './BuilderPage/ui/Spinner/Spinner';

const AllBoostersPage = () => {
  const [isSpinOpen, setIsSpinOpen] = useState<boolean | null>(null);

  const [fetchBoosters, { data: boosters, isLoading, isFetching }] = useLazyGetParticipantBoostersQuery();
  const [checkSpin, { data: dailySpinStatus, isLoading: dailySpinStatusLoading }] = useLazyCheckSpinQuery();

  useEffect(() => {
    if (isSpinOpen === null) {
      if (dailySpinStatus) {
        setIsSpinOpen(dailySpinStatus.canSpinToday);
      } else {
        checkSpin();
      }
    }
  }, [dailySpinStatus]);

  useEffect(() => {
    fetchBoosters(undefined);
  }, []);

  const onCloseHandler = () => {
    setIsSpinOpen(false);
    checkSpin();
  };

  return (
    <>
      {!dailySpinStatusLoading && (
        <Fade in={true}>
          {isSpinOpen && <Spinner onClose={onCloseHandler} dailySpinStatus={dailySpinStatus} />}

          {!isSpinOpen && (
            <Boosters
              isLoading={isLoading || isFetching}
              boosters={boosters}
              title="My Boosters"
              subTitle="Earn coins on top of PTS"
            />
          )}
        </Fade>
      )}
    </>
  );
};

export default AllBoostersPage;
