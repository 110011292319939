import { FC, useEffect, useState } from 'react';

import { Box, BoxProps, Flex, Img } from '@chakra-ui/react';
import { Booster, Card, Contest } from 'models';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

const DetailedCard: FC<{ card: Card; booster?: Booster | null }> = ({ card, booster }) => {
  const headshot =
    card?.headshotThumbnails?.small ?? card?.headshotThumbnails?.medium ?? card?.headshotThumbnails?.original ?? '';
  const [hasModifier, setHasModifier] = useState(false);

  useEffect(() => {
    if (card?.modifier) {
      setHasModifier(true);
    } else {
      setHasModifier(false);
    }
  }, [card]);

  return (
    <Box px="12px" pt="12px" pb="24px">
      <Flex gap="8px" alignItems="center">
        <Box
          w="44px"
          h="44px"
          mx="auto"
          backgroundSize="cover"
          position="relative"
          borderRadius="16px"
          flexShrink="0"
          flexGrow="0"
          bgColor={card.status === 'bench' ? '#343434' : card.status === 'cut' ? '#B83B3B' : 'white'}
          _before={{
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: '16px',
            backgroundImage: `url(${headshot})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            filter: card.status === 'cut' ? 'grayscale(100%)' : '',
          }}
        />
        <Box color="white" flexBasis="100%">
          <Box
            textTransform="uppercase"
            letterSpacing="4%"
            fontFamily="JetBrains Mono"
            fontSize="12px"
            fontWeight="500"
            lineHeight="14.4px"
          >
            {card?.playerFirstName}
          </Box>
          <Box
            textTransform="uppercase"
            fontFamily="JetBrains Mono"
            fontSize="18px"
            fontWeight="500"
            lineHeight="18px"
            mt="2px"
          >
            {card?.playerLastName}
          </Box>
        </Box>
        <Flex flexShrink="0" alignItems="center" justifyContent="center" height="100%" gap="2px">
          <Img w="16px" src="/coin.png" />
          <Box fontSize="18px" fontWeight="700">
            {card.totalPoints}
          </Box>
        </Flex>
      </Flex>
      <Box pl="52px" fontSize="13px" pt="4px" fontWeight="500" color="#FFFFFFCC" lineHeight="15.6px">
        <Flex align="center" justify="space-between">
          <Box>Points in the game</Box>
          <Box
            fontSize="13px"
            fontWeight="600"
            color="white"
            flexBasis="100px"
            flexShrink="0"
            flexGrow="0"
            textAlign="right"
            lineHeight="15.6px"
          >
            {card.nakedScorePoints}
          </Box>
        </Flex>
        {booster && (
          <>
            <Flex align="center" justify="space-between" mt="8px">
              <Box>{capitalizeFirstLetter(booster.rarity)} PTS Boost</Box>
              <Box
                fontSize="13px"
                fontWeight="600"
                color="white"
                flexBasis="100px"
                flexShrink="0"
                flexGrow="0"
                lineHeight="15.6px"
                textAlign="right"
              >
                {booster.pointsMultiplier}x
              </Box>
            </Flex>
            <Flex align="center" justify="space-between" mt="8px">
              <Box>
                {capitalizeFirstLetter(booster.rarity)} {booster.attributeDisplay?.toUpperCase()} Boost
              </Box>
              <Box
                fontSize="13px"
                fontWeight="600"
                color="white"
                flexBasis="100px"
                lineHeight="15.6px"
                flexShrink="0"
                flexGrow="0"
                textAlign="right"
              >
                {booster.attributeMultiplier} x {card.nakedAttributePoints} {booster.attributeDisplay?.toUpperCase()}
              </Box>
            </Flex>
          </>
        )}
        {hasModifier && (
          <Flex align="center" justify="space-between" mt="8px">
            <Box>Contest Bonus Points</Box>
            <Box
              fontSize="13px"
              fontWeight="600"
              color="white"
              flexBasis="100px"
              flexShrink="0"
              flexGrow="0"
              textAlign="right"
              lineHeight="15.6px"
            >
              {card?.modifierPoints}
            </Box>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default DetailedCard;
