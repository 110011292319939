import { useEffect, useRef, useState } from 'react';

import { Box, Flex, keyframes } from '@chakra-ui/react';

interface RunningLineProps {
  direction?: 'left' | 'right';
}

const RunningLine = ({ direction = 'left' }: RunningLineProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [animationDuration, setAnimationDuration] = useState(20);
  const bannerText = 'START BENCH CUT   ·   ';

  const scrollAnimationLeft = keyframes`
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  `;

  const scrollAnimationRight = keyframes`
    0% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(0);
    }
  `;

  const scrollAnimation = direction === 'left' ? scrollAnimationLeft : scrollAnimationRight;

  useEffect(() => {
    const calculateAnimationParams = () => {
      if (containerRef.current && contentRef.current) {
        const containerWidth = containerRef.current.offsetWidth;

        const singleTextWidth = contentRef.current.offsetWidth / (contentRef.current.childNodes.length || 1);
        const repeatsNeeded = Math.max(4, Math.ceil((containerWidth * 2) / singleTextWidth));

        const calculatedDuration = Math.max(20, Math.min(30, containerWidth / 50));
        setAnimationDuration(calculatedDuration);

        contentRef.current.style.display = 'none';
        void contentRef.current.offsetHeight;
        contentRef.current.style.display = 'flex';
      }
    };

    calculateAnimationParams();

    window.addEventListener('resize', calculateAnimationParams);
    return () => window.removeEventListener('resize', calculateAnimationParams);
  }, [direction]);

  const generateContent = () => {
    const repeatCount = 12;
    return Array(repeatCount)
      .fill(bannerText)
      .map((text, index) => <span key={index}>{text}</span>);
  };

  return (
    <Flex
      ref={containerRef}
      overflow="hidden"
      fontFamily="Jetbrains Mono"
      width="100%"
      p="8px"
      bg="#F83C3C"
      color="white"
      alignItems="center"
      position="relative"
      fontWeight="500"
      fontSize="12px"
    >
      <Box
        ref={contentRef}
        whiteSpace="nowrap"
        animation={`${scrollAnimation} ${animationDuration}s linear infinite`}
        letterSpacing="0"
        textTransform="uppercase"
        display="flex"
        sx={direction === 'right' ? { transform: 'translateX(-50%)' } : {}}
      >
        {generateContent()}
        {generateContent()}
      </Box>
    </Flex>
  );
};

export default RunningLine;
