import { useCallback, useEffect, useState } from 'react';

import { Box, Flex, Grid, SlideFade, Text } from '@chakra-ui/react';

import Leaf from './Leaf';
import { feedbacks } from './consts';

const FeedbackSlider = () => {
  const [currentFeedback, setCurrentFeedback] = useState<(typeof feedbacks)[0] | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  const getRandomFeedback = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * feedbacks.length);
    return feedbacks[randomIndex];
  }, []);

  useEffect(() => {
    let showTimer: NodeJS.Timeout;
    let hideTimer: NodeJS.Timeout;

    const showNewFeedback = () => {
      setCurrentFeedback(getRandomFeedback());
      setIsVisible(true);

      hideTimer = setTimeout(() => {
        setIsVisible(false);

        showTimer = setTimeout(showNewFeedback, 200);
      }, 2500);
    };

    showTimer = setTimeout(showNewFeedback, 500);

    return () => {
      clearTimeout(showTimer);
      clearTimeout(hideTimer);
    };
  }, [getRandomFeedback]);

  return (
    <Grid minH="60px" width="100%" mx="auto" p="0px 0px 16px" gridTemplateColumns="47px 1fr 47px" gap="0">
      <Flex>
        <Leaf />
      </Flex>

      <SlideFade
        in={isVisible}
        offsetY="0px"
        style={{ display: 'flex', flexDirection: 'column', gap: '8px', justifyContent: 'center', width: '100%' }}
        transition={{ enter: { duration: 0.2 }, exit: { duration: 0.2 } }}
      >
        {currentFeedback && (
          <>
            <Text
              fontFamily="JetBrains Mono"
              fontSize="14px"
              textTransform="uppercase"
              letterSpacing="2%"
              lineHeight="1.1"
              fontWeight="400"
              textAlign="center"
              style={{ textWrap: 'balance' }}
            >
              "{currentFeedback.phrase}"
            </Text>
            <Text
              fontFamily="JetBrains Mono"
              fontSize="12px"
              textTransform="uppercase"
              letterSpacing="0%"
              lineHeight="1.1"
              fontWeight="400"
              textAlign="center"
              opacity="0.6"
            >
              {currentFeedback.user}
            </Text>
          </>
        )}
      </SlideFade>

      <Flex>
        <Leaf reverse />
      </Flex>
    </Grid>
  );
};

export default FeedbackSlider;
