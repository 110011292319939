import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Box, Text } from '@chakra-ui/react';

import { useGetLeaderboardQuery, useLazyGetLeaderboardQuery } from 'api/leaderboard';

import Leaderboard from 'components/leaderboard/Leaderboard';
import LeaderboardHeader from 'components/leaderboard/LeaderboardHeader';
import LeaderboardPeriodControls from 'components/leaderboard/LeaderboardPeriodControls';
import LeaderboardSkeleton from 'components/leaderboard/LeaderboardSkeleton';
import NotStartedMessage from 'components/leaderboard/NotStartedMessage';
import LeaderboardFriendsFilter from 'components/leaderboard/leaderboardFriendsFilter';

import { useAppSelector } from 'hooks/app';

const LeaderboardPage: FC = () => {
  const isAuthicated = useAppSelector((store) => store.auth.isAuthicated);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsContestId = searchParams.get('contestId');
  const searchParamsFriends = searchParams.get('friends');

  const [getStat, { data: stat, isFetching: isLeaderbordFetching, isError }] = useLazyGetLeaderboardQuery();

  useEffect(() => {
    if (searchParamsContestId !== null && searchParamsContestId !== undefined) {
      getStat({
        contestId: searchParamsContestId,
        friends: searchParamsFriends === 'true',
      });
    }

    if (!searchParamsContestId) {
      getStat({ contestId: null, friends: searchParamsFriends === 'true' });
    }
  }, [searchParamsContestId, searchParamsFriends]);

  const handleChangeContestId = (id: number) => {
    setSearchParams((sp) => {
      const newParams = new URLSearchParams(sp);
      newParams.set('contestId', `${id}`);
      return newParams;
    });
  };

  const isFetching = isLeaderbordFetching;
  const isLocked = stat?.currentContest.status === 'locked';
  const isCompleted = stat?.currentContest.status === 'completed';
  const isActive = stat?.currentContest.status === 'active';
  const isLoading = !isFetching && !!stat;

  return (
    <Box display="flex" flexDirection="column" h="100%" gridTemplateRows="auto auto 1fr" px="12px">
      {isAuthicated ? (
        <LeaderboardFriendsFilter />
      ) : (
        <Text textAlign="left" variant="pageTitle">
          leaderboard
        </Text>
      )}
      <LeaderboardPeriodControls innitialActivePeriod={'daily'} />
      {isFetching && <LeaderboardSkeleton />}

      {isLoading && (
        <>
          {(isCompleted || isLocked || isActive) && (
            <Leaderboard
              key={stat?.currentContest.id}
              stat={stat}
              participant={stat.me}
              showLeaderboard={!isLocked && !isActive}
              showMySquad={
                (((isLocked && stat.me?.status === 'completed') || (isCompleted && stat.me?.status === 'completed')) &&
                  !isError) ||
                (isActive && stat.me?.status === 'completed')
              }
              showInviteRow={searchParamsFriends === 'true'}
              showNoParticipants={!isLocked}
            />
          )}
        </>
      )}

      <LeaderboardHeader
        disabled={isFetching}
        stat={stat}
        onNext={handleChangeContestId}
        onPrev={handleChangeContestId}
      />
    </Box>
  );
};

export default LeaderboardPage;
