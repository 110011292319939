const routes = {
  home: '/',
  builder: '/builder',
  signIn: '/signin',
  leaderboard: '/leaderboard',
  boosters: '/boosters',
  squad: '/squad',
  allBoosters: '/all-boosters',
  logout: '/logout',
  invite: '/invite',
  streakPromo: '/streak-promo',
  profile: '/profile',
  editName: '/profile/edit/name',
  editBio: '/profile/edit/bio',
  editAvatar: '/profile/edit-avatar',
  editFakeUser: '/edit/user',
  prizes: '/prizes',
};

export default routes;
