import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import routes from 'routes';

import Boosters from 'components/booster/Boosters';

import { useAppSelector } from 'hooks/app';
import useBoosters from 'hooks/useBoosters';

const BoostersPage = () => {
  const contest = useAppSelector((state) => state.contest.contest);
  const participation = useAppSelector((state) => state.contest.participation);
  const { boosters, isLoading, setBooster, fetchBoosters } = useBoosters({ contest });

  useEffect(() => {
    if (contest) {
      fetchBoosters();
    }
  }, [contest]);

  if (contest?.status !== 'active' || !participation || !contest) {
    return <Navigate to={routes.home} replace />;
  }

  if (participation?.status === 'cards_pending') {
    return <Navigate to={routes.builder} replace />;
  }

  if (participation.booster || participation.status === 'completed') {
    return <Navigate to={routes.squad} replace />;
  }

  return (
    <Boosters
      boosters={boosters}
      onSelect={setBooster}
      isLoading={isLoading}
      title="Use a boost"
      subTitle="Earn coins on top of PTS"
    />
  );
};

export default BoostersPage;
