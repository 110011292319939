import { Link } from 'react-router-dom';

import { Box, Button, Fade, Flex, Image } from '@chakra-ui/react';
import routes from 'routes';

import { ReactComponent as SparkIcon } from 'icons/spark.svg';

interface NoBoosterSubmitModalProps {
  onClose: () => void;
  onSubmit: () => void;
  show: boolean;
}

const NoBoosterSubmitModal = ({ onClose, onSubmit, show }: NoBoosterSubmitModalProps) => {
  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  return (
    <Fade
      in={show}
      unmountOnExit
      style={{
        position: 'absolute',
        top: '0',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'rgba(36, 36, 36, 0.96)',
        width: '100vw',
        height: '100dvh',
        zIndex: 99990,
        display: 'flex',
        alignItems: 'flex-end',
        maxWidth: '500px',
        padding: '12px 8px',
      }}
      onClick={handleBackdropClick}
    >
      <Flex
        flexDirection="column"
        gap="20px"
        p="20px"
        backgroundColor="rgba(36, 36, 36, 1)"
        borderRadius="16px"
        border="1px solid rgba(255, 255, 255, 0.1)"
        w="100%"
      >
        <Flex justifyContent="space-between">
          <Flex flexDirection="column" gap="12px">
            <Box fontSize="24px" fontWeight="800" lineHeight="1.1" whiteSpace="balance">
              Submit squad without boost?
            </Box>
            <Box fontSize="15px" fontWeight="600" lineHeight="1.2" whiteSpace="balance">
              All good, but score and rank will be lower
            </Box>
          </Flex>
          <Flex justifyContent="flex-end">
            <SparkIcon />
          </Flex>
        </Flex>
        <Flex w="100%" gap="12px">
          <Button as={Link} to={routes.boosters} flex="1">
            pick boost
          </Button>
          <Button
            onClick={() => {
              onSubmit();
              onClose();
            }}
            variant="secondary"
            flex="1"
          >
            yeah
          </Button>
        </Flex>
      </Flex>
    </Fade>
  );
};

export default NoBoosterSubmitModal;
