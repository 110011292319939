import { useEffect, useRef, useState } from 'react';
import useMeasure from 'react-use-measure';

import { Box, Flex, keyframes } from '@chakra-ui/react';
import { animate, motion, useMotionValue } from 'framer-motion';

import { useGetBoostersQuery } from 'api/contest';

import Slide from './Slide';
import SlideSkeleton from './SlideSkeleton';

const move = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
`;

const fadeAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

interface SliderProps {
  slidesType?: 'blazing' | 'normal';
}

const Slider = ({ slidesType }: SliderProps) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const { data, isFetching } = useGetBoostersQuery();
  const slides = data?.filter((slide) =>
    slidesType === 'blazing' ? slide.rarity === 'blazing' : ['epic', 'legendary', 'rare'].includes(slide.rarity)
  );

  const [ref, { width }] = useMeasure();
  const xTransition = useMotionValue(0);
  useEffect(() => {
    const finalPosition = -width / 2 - 15;
    const controls = animate(xTransition, [0, finalPosition], {
      ease: 'linear',
      duration: slidesType === 'blazing' ? 20 : 60,
      repeat: Infinity,
      repeatType: 'loop',
      repeatDelay: 0,
    });

    return () => controls.stop();
  }, [xTransition, width]);

  useEffect(() => {
    const handleResize = () => {
      if (sliderRef.current) {
        setHeight(sliderRef.current.clientHeight);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Flex ref={sliderRef} width="100%" whiteSpace="nowrap" alignItems="center">
      {isFetching ? (
        <Box
          opacity="0"
          animation={`${move} 15s linear infinite, ${fadeAnimation} 0.5s ease-out forwards 0.2s`}
          display="inline-flex"
        >
          {Array.from({ length: 14 }).map((_, i) => (
            <Box key={i} mr="30px">
              <SlideSkeleton />
            </Box>
          ))}
        </Box>
      ) : (
        <motion.div
          ref={ref}
          style={{ x: xTransition, display: 'flex', height: '100%', alignItems: 'center', gap: '30px' }}
        >
          {slides && [...slides, ...slides]?.map((slide, i) => <Slide key={i} slide={slide} />)}
        </motion.div>
      )}
    </Flex>
  );
};

export default Slider;
