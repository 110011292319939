import { forwardRef, useEffect, useRef, useState } from 'react';

import { Box, Fade, Flex, Grid, Img, Portal } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Contest, ContestParticipant } from 'models';

import CardPlaceholder from 'components/builder/CardPlaceholder';
import Squad from 'components/builder/Squad/Squad';

import { ReactComponent as NoneIcon } from 'icons/none.svg';

interface ContestHistoryItemProps {
  participation: ContestParticipant;
}

const ContestHistoryItem = forwardRef<HTMLDivElement, ContestHistoryItemProps>(({ participation }, ref) => {
  const cardPlaceholderWrapperRef = useRef<HTMLDivElement>(null);
  const [cardOffset, setCardOffset] = useState(0);
  const [showParticipationDetail, setShowParticipationDetail] = useState(false);
  const formattedDate = dayjs(participation.contest?.startsAt).format('M/DD');
  const { place, contest, totalPoints, booster, cards, updated, user } = participation;
  const startCards = cards.cardsWithStatus.filter((card) => card.status === 'start');
  const benchCard = cards.cardsWithStatus.find((card) => card.status === 'bench');

  useEffect(() => {
    if (cardPlaceholderWrapperRef.current) {
      const offsetDiff = cardPlaceholderWrapperRef.current.offsetWidth / 5;
      setCardOffset(
        cardPlaceholderWrapperRef.current.offsetWidth / 4 > 64
          ? offsetDiff - 1.8
          : offsetDiff - (64 - cardPlaceholderWrapperRef.current.offsetWidth / 4)
      );
    }
  }, [cardPlaceholderWrapperRef.current]);

  if (showParticipationDetail) {
    const date = dayjs(updated).tz('America/Los_Angeles');
    const dateStr = date.format('MMM D');

    return (
      <Portal>
        <Squad
          participation={participation}
          cards={cards.cardsWithStatus}
          pos="fixed"
          top="0"
          bottom="0"
          left="0"
          right="0"
          bg="var(--chakra-colors-bg)"
          onClose={() => setShowParticipationDetail(false)}
          title={`${user.username}`}
          description={`Start Bench Cut · ${dateStr}`}
          overflow="auto"
          detailed
          zIndex="100"
          contestStatus={'completed'}
          className="portal"
        />
      </Portal>
    );
  }

  return (
    <Fade in>
      <Flex
        w="100%"
        borderBottom="0.5px solid #FFFFFF4D"
        onClick={() => setShowParticipationDetail(true)}
        cursor="pointer"
        transition="all 0.2s ease-in-out"
        _active={{ backgroundColor: 'rgba(255,255,255,0.05)' }}
      >
        <Grid
          ref={ref}
          w="100%"
          maxW="375px"
          mx="auto"
          gridTemplateRows="36px 1fr"
          gridTemplateColumns="60px auto"
          p="4px 12px 16px"
        >
          <Flex
            fontSize="14px"
            fontWeight="800"
            lineHeight="1.2"
            textAlign="left"
            alignItems="center"
            color={place === 1 ? 'rgba(251, 188, 5, 1)' : 'white'}
          >
            {formattedDate}
          </Flex>
          <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
            <Box
              fontSize="14px"
              fontWeight="800"
              lineHeight="1.2"
              textAlign="left"
              textTransform="uppercase"
              color={place === 1 ? 'rgba(251, 188, 5, 1)' : 'white'}
            >
              {place ? `#${place}` : '--'} @ {contest?.league}
            </Box>
            <Flex flexDirection="row" gap="4px" alignItems="center">
              <Box fontSize="14px" fontWeight="800" lineHeight="1.2">
                {contest?.status !== 'completed' ? 'TBA' : totalPoints}
              </Box>
              <Img w="16px" h="16" objectFit="contain" src="/coin.png" />
            </Flex>
          </Flex>
          <Box />
          <Flex
            ref={cardPlaceholderWrapperRef}
            justifyContent="flex-end"
            flexDirection="row"
            w="100%"
            h="64px"
            flexWrap="nowrap"
            pos="relative"
          >
            {startCards.map((card, index) => (
              <CardPlaceholder
                key={card.id}
                minW="64px"
                boxShadow="4px 0px 20px 0px #00000080"
                card={card}
                position="absolute"
                left={`${index * cardOffset}px`}
                // transition="all 0.3s"
              />
            ))}
            {benchCard && (
              <CardPlaceholder
                boxShadow="4px 0px 20px 0px #00000080"
                bgColor="#4F4F4F"
                color="#D2C9C9"
                card={benchCard}
                position="absolute"
                left={`${startCards.length * cardOffset}px`}
                // transition="all 0.3s"
              />
            )}
            {booster ? (
              <Box
                pos="relative"
                w="64px"
                h="64px"
                backgroundColor={booster.backgroundColor ?? 'transparent'}
                borderRadius="14px"
                backgroundImage={`url(${booster.image})`}
                backgroundSize="cover"
                backgroundPosition="center center"
                boxShadow="4px 0px 20px 0px #00000080"
                overflow="hidden"
                position="absolute"
                left={`${(startCards.length + 1) * cardOffset}px`}
                // transition="all 0.3s"
              >
                {booster.icon && (
                  <Img
                    src={booster.icon}
                    pos="absolute"
                    top="50%"
                    left="50%"
                    w="64px"
                    h="64px"
                    objectFit="cover"
                    objectPosition="center center"
                    transform="translate(-50%, -50%)"
                  />
                )}
              </Box>
            ) : (
              <Box
                pos="absolute"
                w="64px"
                h="64px"
                backgroundColor="#111111"
                borderRadius="14px"
                boxShadow="4px 0px 20px 0px #00000080"
                left={`${(startCards.length + 1) * cardOffset}px`}
                // transition="all 0.3s"
              >
                <Box as={NoneIcon} pos="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" />
              </Box>
            )}
          </Flex>
        </Grid>
      </Flex>
    </Fade>
  );
});

export default ContestHistoryItem;
