import { useState } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Flex, Grid, Image, SlideFade, Text } from '@chakra-ui/react';
import routes from 'routes';

import FeedbackSlider from 'components/feedback-slider/FeedbackSlider';
import Slider from 'components/pages/StreakPromoPage/Slider';

import { ReactComponent as ClutchpointsIcon } from 'icons/clutchpoints.svg';

import Banner from './banner';
import CoinCounter from './coin-counter';

const firstSpinImage =
  'https://clutchpoints-store-media.s3.us-east-1.amazonaws.com/SBC+media/booster-icon-placeholders/sbc-welcome-pack.webp';
type GuestHomeProps = {
  customOnClick?: () => void;
};
const GuestHome = ({ customOnClick }: GuestHomeProps) => {
  const [loaded, setLoaded] = useState(false);
  const [showCup, setShowCup] = useState(false);
  const navigate = useNavigate();

  const handleVibrate = () => {
    ReactGA.event({
      category: 'GuestHome',
      action: 'click',
      label: 'Join button',
    });
    if (customOnClick) {
      customOnClick();
    } else {
      navigate(routes.signIn);
    }
  };

  return (
    <>
      <Grid
        w="100%"
        h="100%"
        gridTemplateRows="auto 1fr auto"
        pt="20px"
        pb="20px"
        transition="opacity 0.2s ease-in-out"
      >
        <Banner />
        <Box
          textAlign="center"
          fontSize="28px"
          fontWeight="800"
          lineHeight="1.1"
          letterSpacing="-2%"
          style={{ textWrap: 'balance' }}
        >
          Build your squad.
          <br />
          Score from games.
          <br />
          Clash for prizes.
        </Box>
        <Flex justify="center" mt="20px" mb="20px">
          <Button onClick={handleVibrate} data-cy="join-button">
            join the game
          </Button>
        </Flex>
        <Box px="24px" mb="48px">
          <FeedbackSlider />
        </Box>

        <Text
          fontSize="24px"
          fontWeight="800"
          lineHeight="1.1"
          letterSpacing="-2%"
          style={{ textWrap: 'balance' }}
          textAlign="center"
        >
          How to play
          <br />
          Start Bench Cut
        </Text>
        <Box px="24px" mt="20px" mb="20px">
          <Image src="home/sbc_example-squad.webp" alt="squad example" w="100%" mx="auto" />
        </Box>
        <Text
          fontSize="18px"
          fontWeight="700"
          lineHeight="1.2"
          style={{ textWrap: 'balance' }}
          textAlign="center"
          mt="0px"
          mb="64px"
        >
          Pick 3 players who go off in real games. Score from their stats. Results drop when it’s over.
        </Text>

        <Flex flexDirection="column" alignItems="center" justifyContent="center" mt="20px" mb="30px" gap="8px">
          <CoinCounter coins={399} onCountingComplete={() => setShowCup(true)} />
          <SlideFade in={showCup} offsetY="0px" transition={{ enter: { duration: 0.5 }, exit: { duration: 0.5 } }}>
            <Text
              fontSize="16px"
              fontWeight="700"
              lineHeight="1.2"
              style={{ textWrap: 'balance' }}
              textAlign="center"
              mt="0px"
            >
              🏆 you’re 1st of 2,139
            </Text>
          </SlideFade>
        </Flex>

        <Flex justify="center" alignItems="center" flexDirection="column" mt="20px" mb="70px">
          <SlideFade
            in={showCup}
            offsetY="20px"
            transition={{ enter: { duration: 0.5, delay: 0.2 }, exit: { duration: 0.5 } }}
          >
            <Image src="home/sbc_board-row_01.webp" alt="board row" w="100%" maxW="355px" px="" mx="auto" />
          </SlideFade>

          <SlideFade
            in={showCup}
            offsetY="20px"
            transition={{ enter: { duration: 0.5, delay: 0.4 }, exit: { duration: 0.5 } }}
          >
            <Image src="home/sbc_board-row_02.webp" alt="board row" w="100%" maxW="355px" px="" mx="auto" />
          </SlideFade>

          <SlideFade
            in={showCup}
            offsetY="20px"
            transition={{ enter: { duration: 0.5, delay: 0.6 }, exit: { duration: 0.5 } }}
          >
            <Image src="home/sbc_board-row_03.webp" alt="board row" w="100%" maxW="355px" px="" mx="auto" />
          </SlideFade>
        </Flex>

        <Flex
          pos="relative"
          justify="center"
          alignItems="center"
          flexDirection="column"
          mt="20px"
          mb="20px"
          overflow="hidden"
          minHeight="360px"
          width="100%"
        >
          <Image src={firstSpinImage} h="360px" zIndex="2" />
          <Box
            w="100%"
            h="100%"
            pos="absolute"
            opacity={0.6}
            top="0"
            left="0"
            right="0"
            bottom="0"
            zIndex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Slider slidesType="normal" />
          </Box>
        </Flex>
        <Flex flexDirection="column" alignItems="center" justifyContent="center" gap="12px">
          <Text
            fontSize="24px"
            fontWeight="800"
            lineHeight="1.1"
            letterSpacing="-2%"
            style={{ textWrap: 'balance' }}
            textAlign="center"
          >
            Sign up and grab your Welcome booster
          </Text>
          <Text
            fontSize="14px"
            fontWeight="500"
            lineHeight="1.1"
            style={{ textWrap: 'balance' }}
            textAlign="center"
            opacity="0.8"
          >
            Must be a new player
          </Text>
        </Flex>
        <Flex justify="center" mt="20px" mb="20px">
          <Button onClick={handleVibrate} data-cy="join-button">
            join the game
          </Button>
        </Flex>
        <Box as={ClutchpointsIcon} display="block" mx="auto" mt="32px" mb="24px" />
      </Grid>
    </>
  );
};
export default GuestHome;
